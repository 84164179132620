

export const data = {
    'story' : {
        title: 'LET THE BATTLE BEGIN',
        text: ['Tensions have been rising between the Gods and the Titans and now they\'re gearing up to see who will prevail, in a battle that will decide who rules the universe. Now it\'s time to pick your side of history, armor up and join the battle by owning a Gods and Titans NFT. ',
                'Every NFT character is crafted by our very own DC Comics artist and gives you access to our Holder Rewards Utility. Under this utility sits a range of different products that generate different rewards. The first product is OpenSea lifetime Royalties where you can earn 50% royalties for life.',
                'The second product is the Gods & Titans Roblox Game Series, where you can generate additional royalty earnings. And the third product is the NFT Comicverse, where you can integrate your Gods & Titans NFT into a series of bespoke Comic Book NFTs.'
            ],
        
    },
    'comicverse' : {
        title: 'THE COMIC STUDIO',
        text: ['The only place in the NFT space where the community can create, collaborate and mint their very own one-of-a-kind comic NFTs for free. This is just one of the ways we\'re giving back to our community, by creating a platform that allows owners to become creators in multiple community-run NFT drops.'],
       
    },
    'acropolis':{
        // title: ' MINTING 2.0',
        text: ['We’re rewarding our community with an epic range of holder rewards. When you mint, you gain access to a range of different benefits like being able to earn 50% royalties for life, paid out in ETH. The option to opt-in to receive royalty earnings from the Gods & Titans Roblox Game, and access to the Comic Studio where you can create and mint Comic Book NFTs.',
        'Enter The Vault Acropolis above to find out more.'
                // 'Along with Minting 2.0, people will also be able to help make a difference to the lives of those in and outside of the NFT space by opting to donate to the National Suicide Prevention Lifeline.'
            ],
        preview: 'We\'re rewarding our community by changing minting for good - when you mint, you can earn 50% of all secondary royalties for life',
        mobileTitle: 'let the battle begin'
    },
     'comic' : {
        title: 'OWNING YOUR COMIC NFT',
        text: [
            'Once you\’ve minted your Comic Book NFT in the Comic Studio, you\’ll have full control over it. You\’ll be able to hold it or sell it on OpenSea to earn royalties on it.',
            'As you can see, Gods & Titans NFTs generate multiple Holder Rewards for collectors. Check out our whitepaper for more details on all the benefits that come with minting a Gods & Titans NFT.'
            
        ], 
        // subtext: 'With every Innovation Labs mint, you will still get 4% of the royalties. However, you cannot earn dividends with our free mint comics.',
        preview: 'Once you\'ve minted your free comic NFT in the Comicverse Studio, you\'ll have full control over it.'
    },
    'roadmapText':{
        title: 'roadmap',
        text: ['Our Project Roadmap is split into 2 Phases. In Phase 1 there will be 3 drops of 4000 Gods and Titans NFTs and the launch of the GAT Roblox Game Series. NFT Drop 1 will be split into 2 separate mints, each with only 2000 GAT NFTs. Following this, Phase 2 will see the launch of the Comic Studio - Comic Book NFT Series 1 - Titanomachy, Comic Book NFT Series 2 - Golden Fleece, Comic Book NFT Series 3 - Trojan War. ']
    },
    'roadmap': [
        {
            title: 'Drop 1:',
            text:' 4000 GAT NFTs: 2000 Gods + 2000 Titans'
        },
         {
            title: 'Drop 2:',
            text:' 4000 GAT NFTs: Demigods & gods'
        },
         {
            title: 'Drop 3:',
            text:' 4000 GAT NFTs: Demigods & Gods'
        },
         {
            
            title: 'Launching GAT Roblox Game'
        },
        {
            
            title: 'Access to Comic Studio'
        },
         {
            title: 'Drop 5:',
            text:'Comic Book NFT Series 1'
        },
         {
            title: 'Drop 6:',
            text:'Comic Book NFT Series 2'
        },
          {
            title: 'Drop 7:',
            text:'Comic Book NFT Series 3'
        },
        // {
        //     title: 'Drop 7:',
        //     text:'DC Comics Artist\'s Art Book NFT'
        // },
        //  {
        //     title: 'Airdrop:',
        //     text: 'Sketchbook'
        // },
        
    ]
    ,
     'chardev' : {
        text: ['Each of our NFTs are hand-drawn by the legendary Omar Francia. He\'s the powerhouse behind DC Comics, Warner Bros with over 20 years of experience in the industry. Omar has brought to life some of the biggest projects in the world, like Batman, Star Wars, Mass Effect, PUBG and now Gods & Titans.',
                'After extensive research and then ideation, Omar takes to the pencil, sketching rough outlines before moving on to crafting these into beautifully detailed inks. He then completes each masterpiece by coloring the characters digitally. Every NFT will be different with bespoke traits that will determine its rarity and XP rating. One limited edition gold version of each character will also be released - these gold NFT characters will be the rarest.'],
        preview: 'Each of our NFTs are hand-drawn by the legendary Omar Francia, the powerhouse behind DC Comics',
        mobileTitle: 'character development'
    },
    'team' : [
         {
            name: 'mike',
            role: 'chief executive officer',
            link: 'https://www.linkedin.com/in/michael-olavarria-809a109/',
            imgPath: '/team/cto.png'
        },
         {
            name: 'ankesh',
            role: 'chief technology officer',
            link: 'https://www.linkedin.com/in/ankesh27/',
            imgPath: '/team/anki.png'
        },
         {
            name: 'rey',
            role: 'full-stack developer',
            link: 'https://twitter.com/innoDev_eth',
            imgPath: '/team/dev.png'
        }, 
        {
            name: 'omar',
            role: 'head character artist',
            link: 'https://instagram.com/omarfrancia?igshid=YmMyMTA2M2Y=',
            imgPath: '/team/omar.png'
        },
        {
            name: 'connyr',
            role: 'marketing + art director',
            link: 'https://www.linkedin.com/in/connyr-snell-09214212a',
            imgPath: '/team/connyr.png'
        },
        {
            name: 'cain',
            role: 'Project manager + technical writer',
            imgPath: '/team/cain.jpg',
             link: 'https://www.linkedin.com/in/cain-duff-5a74a134/',
        },
         {
            name: 'benjamin',
            role: 'website + promo artist',
            link: 'http://www.benleon.me/',
            imgPath: '/team/ben.jpg'
        }, 
         {
            name: 'diego',
            role: 'comic book artist',
            link: 'https://instagram.com/diegogrecoart?igshid=YmMyMTA2M2Y=',
            imgPath: '/team/diego.jpg'
        },
        {
            name: 'Edwin',
            role: 'comic book author',
            link: '',
             imgPath: '/team/ed.jpg'
        },  {
            name: 'Lesly',
            role: 'Strategist',
            link: 'https://www.linkedin.com/in/leslyramirez',
             imgPath: '/team/les.png'
        }, 
  
    ],
    'pairs': [
           [ {
            name: 'mike',
            role: 'chief executive officer',
            link: 'https://www.linkedin.com/in/michael-olavarria-809a109/',
            imgPath: '/team/cto.png'
        },
         {
            name: 'ankesh',
            role: 'chief technology officer',
            link: 'https://www.linkedin.com/in/ankesh27/',
            imgPath: '/team/anki.png'
        },],
        [{
            name: 'rey',
            role: 'full-stack developer',
            link: 'https://twitter.com/innoDev_eth',
             imgPath: '/team/dev.png'
        },     
        {
            name: 'omar',
            role: 'head character artist',
            link: 'https://instagram.com/omarfrancia?igshid=YmMyMTA2M2Y=',
             imgPath: '/team/omar.png'
        },],
        [
         {
            name: 'connyr',
            role: 'marketing + art director',
            link: 'https://www.linkedin.com/in/connyr-snell-09214212a',
             imgPath: '/team/connyr.png'
        },
         {
            name: 'cain',
            role: 'Project manager + technical writer',
            link: 'https://www.linkedin.com/in/cain-duff-5a74a134/',
            imgPath: '/team/cain.jpg'
        },],
       [ {
            name: 'Edwin',
            role: 'comic book author',
            link: '',
           imgPath:  '/team/ed.jpg'
        },
         {
            name: 'diego',
            role: 'comic book artist',
            link: 'https://instagram.com/diegogrecoart?igshid=YmMyMTA2M2Y=',
            imgPath: '/team/diego.jpg'
        },],
        [
        {
            name: 'benjamin',
            role: 'website + promo artist',
            link: 'http://www.benleon.me/',
            imgPath: '/team/ben.jpg'
        },
        {
            name: 'Lesly',
            role: 'Strategist',
            link: 'https://www.linkedin.com/in/leslyramirez',
            imgPath: '/team/les.png'
        }
    ]
    ],
    'socials':[
        {link: 'https://twitter.com/GAT_NFT',imgPath: '../icons/twitter.png', navImg: '../icons/navTwitter.png',name:'twitter'},
        {link: 'https://discord.gg/MnPeYS2mdG',imgPath: '../icons/discord.png', navImg: '../icons/navDiscord.png',name:'discord'},
        {link: 'https://opensea.io/collection/gods-and-titans-titanomachy-war',imgPath: '../icons/discord.png', navImg: '../icons/navOpensea.png',name:'opensea'},
        // {link: 'https://nftcalendar.io/event/gods-titans-nft-limited-mint/',imgPath: '../icons/nftCal.png', navImg: '../icons/nftCal.png',name:'NFTCalendar'},

        // {link: '#',imgPath: '../icons/opensea.png', navImg: '../icons/navOpensea.png'},
    ]

}