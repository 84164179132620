import React, { useState } from 'react';
import styled from 'styled-components';
import { useMoralis} from "react-moralis"
import Web3 from "web3";
import contractAbi from '../GAT_Companion.json';


const COMPANION_ADDRESS = process.env.REACT_APP_GAT_COMPANION_ADDRESS;
const COMPANION_COLLECTION_NAME =  process.env.REACT_APP_GAT_COMPANION_NAME;
const CONTRACT_OPENSEA_LINK = process.env.REACT_APP_CONTRACT_OPENSEA_LINK


export const RoyaltyModal = ({closePopUp, status,royaltyTokens}) => {
    const [showPopUp, setShowPopUp] = useState(true);
    const [mintBtnText, setMintBtnText] = useState('Mint Royalty Tokens');
    const { isAuthenticated, logout, user, Moralis } = useMoralis();
    const [rewardAmt, updaterewardAmt] = useState(royaltyTokens.length);

    const mintRoyaltyTokens = () => {
        const web3Js = new Web3(Moralis.provider)

        let randomGeneratedHex = web3Js.utils.randomHex(32);

        let authData = user.get('authData');   
        let objectID = user.id;   
        // console.log(objectID)        
        let authDataSiignature = authData.moralisEth.signature;

        let controller = new AbortController();

        let timeoutId = setTimeout(() => controller.abort(), 300000);

        console.log('process mint')
        setMintBtnText('Processing...');
        fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/processMint?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&price=${101}&royalty=${true}`,
        {   
            signal: controller.signal,
            headers:{ 'x-authenticate': authDataSiignature}})
            .then(response =>response.json())
            .then(data => {  
                clearTimeout(timeoutId);       
                console.log(data)

                // retrieve salt and token from firebase function
                const token = data.result.token;
                const salt = data.result.salt;

                // make contact with smart contract if salt & token found
                if( salt && token){


                    // CALL MINT METHOD
                    const contract = new web3Js.eth.Contract(contractAbi.abi, COMPANION_ADDRESS);
                    contract.methods.mint(rewardAmt, salt, token).send({from: user.get('ethAddress'), value: 0}).then(async (e) => {
                        // mint successful
                        console.log(e);

                        let mintedTokenData = [];
                        // let gameRewardsRecieved = 0;

                        if (rewardAmt > 1 ){
                            for(var i=0; i< e.events.Transfer.length; i++){
                                let documentData = {
                                    contractAddress: e.events.Transfer[i].address,
                                    collectionName: COMPANION_COLLECTION_NAME,
                                    walletAddress: user.get('ethAddress'),
                                    tokenId: e.events.Transfer[i].returnValues.tokenId,
                                    gatTokenId: royaltyTokens[i],
                                    mintTime: Date.now(),
                                    transactionHash: e.transactionHash,
                                }

                                    let tokenId=e.events.Transfer[i].returnValues.tokenId;
            
                                    // setup royalty metadata
                                    await fetch(`${ process.env.REACT_APP_FUNCTION_DOMAIN}/updateMetadata?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&tokenid=${tokenId}&gatid=${royaltyTokens[i]}`,
                                    { 
                                        headers:{ 'x-authenticate': authDataSiignature}}
                                    )
                                    .then(response => response.json())
                                    .then(data => {         
                                        console.log(data.result)
                                
                                    });


                            // writes to db with minted wallet info
                                // addDoc(collection(db, "wallet_mint"), documentData);
                                mintedTokenData.push(documentData)
                            }

                        }else{
                            // for(var i=0; i< e.events.Transfer.length; i++){
                                let documentData = {
                                    contractAddress: e.events.Transfer.address,
                                    collectionName: COMPANION_COLLECTION_NAME,
                                    walletAddress: user.get('ethAddress'),
                                    tokenId: e.events.Transfer.returnValues.tokenId,
                                    gatTokenId: royaltyTokens[0],
                                    mintTime: Date.now(),
                                    transactionHash: e.transactionHash,
                                }

                                let tokenId = e.events.Transfer.returnValues.tokenId;

                                // setup royalty metadata
                                    await fetch(`${ process.env.REACT_APP_FUNCTION_DOMAIN}/updateMetadata?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&tokenid=${tokenId}&gatid=${royaltyTokens[0]}`,
                                {
                                    headers:{ 'x-authenticate': authDataSiignature}}
                                )
                                .then(response => response.json())
                                .then(data => {         
                                    console.log(data.result)
                            
                                });



                            // writes to db with minted wallet info
                                mintedTokenData.push(documentData)
                            // }

                        }

                        // console.log(mintedTokenData)
                        // console.log(mintedTokenData.length)
                        
                        if(mintedTokenData.length){
                            let stringData = encodeURIComponent(JSON.stringify(mintedTokenData));
                            
                            // console.log(stringData);

                            // update mint DB
                            fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/updateMintDB?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&data=${stringData}&type=royalty`,
                            {headers:{ 'x-authenticate': authDataSiignature}})
                                .then(response => response.json())
                                .then(data => {
                                        setMintBtnText('Mint Complete');
                                    });

                            // update analytics log
                            let resultInfo = {collection_name : 'click_royalty', status: 'pass', condition: 'mint complete'}
                            let stringResult = encodeURIComponent(JSON.stringify(resultInfo));
                            fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/analyticsUpdate?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&resultinfo=${stringResult}`,
                                {headers:{ 'x-authenticate': authDataSiignature}}).catch(e=>{
                                console.log(e)
                                setMintBtnText('Mint Royalty Tokens');
                                logout(); 
                            })

                        }else{
                            setMintBtnText('Mint Royalty Tokens');
                            logout();  
                        }
                
            

                    }).catch(err=>{
                        setMintBtnText('Mint Royalty Tokens');

                        if(err.code ==! 4001){ 
                            logout();  
                            setShowPopUp(false);
                            closePopUp(false);
                        }

                        // mint did not go through
                        console.log(err);

                        // update analytics log
                        let resultInfo = {collection_name : 'click_royalty', status: 'failed', condition: err.message}
                        resultInfo = encodeURIComponent(JSON.stringify(resultInfo));
                        console.log(resultInfo)
                        fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/analyticsUpdate?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&resultinfo=${resultInfo}`,
                            {headers:{ 'x-authenticate': authDataSiignature}}).catch(e=>{
                                console.log(e)
                                setMintBtnText('Mint Royalty Tokens');
                                logout(); 
                                setShowPopUp(false);
                                closePopUp(false);
                            })
                    })  
        
                } else{
                    setMintBtnText('Mint Royalty Tokens');
                    logout(); 
                    setShowPopUp(false);
                    closePopUp(false);
                }
        }).catch(err=>{
            setMintBtnText('Mint Royalty Tokens');
            logout(); 
            setShowPopUp(false);
            closePopUp(false);

            // mint did not go through
            console.log(err);

            // update analytics log
            let resultInfo = {collection_name : 'click_royalty', status: 'failed', condition: err.message}
            resultInfo = encodeURIComponent(JSON.stringify(resultInfo));
            console.log(resultInfo)
            fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/analyticsUpdate?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&resultinfo=${resultInfo}`,
                {headers:{ 'x-authenticate': authDataSiignature}}).catch(e=>{
                    console.log(e)
                    setMintBtnText('Mint Royalty Tokens');
                    logout(); 
                })
        }) 
        
    }


    const rewardInc = () => {
        let newAmt = rewardAmt + 1;
        let maxTokens  =royaltyTokens.length;

        if (newAmt > maxTokens) newAmt = maxTokens
        updaterewardAmt(newAmt)
    }

    const rewardDec = () => {
        let newAmt = rewardAmt - 1;

        if (newAmt < 1) newAmt = 1
        updaterewardAmt(newAmt)
    }

   

  return (
    <BG  onClick={()=>{
                // setShowPopUp(false);
                // closePopUp(false);
            }}>
    <Container  showPopUp={showPopUp} >
        <Wrapper>
            <CloseBtn src={'./icons/close.png'} onClick={()=>{
                setShowPopUp(false);
                closePopUp(false);
            }}/>
           
            {status.map((text,index)=>{ 
                if (text){
                     return <Heading key={index}>{text}</Heading>
                }
               
            })}

            {status.length == 3 ?
                <>
                <MintWrap>
                            <BtnGroup >
                                <BtnBox onClick={(e)=>{
                                    e.preventDefault()
                                    if(isAuthenticated && mintBtnText==='Mint Royalty Tokens')  { rewardDec() };
                                }}>
                                    <BtnText>-</BtnText>
                                </BtnBox>

                                <TextBox>
                                    <NumText>{rewardAmt}</NumText>
                                </TextBox>

                                <BtnBox onClick={(e)=>{
                                    e.preventDefault()
                                    if(isAuthenticated  && mintBtnText==='Mint Royalty Tokens')  { rewardInc() };
                                }}>
                                    <BtnText>+</BtnText>
                                </BtnBox>

                            </BtnGroup>
                               
                <MintBtnContainer 
                
                    onClick={(e)=>{
                        e.preventDefault()
                        
                        if(isAuthenticated && mintBtnText=='Mint Royalty Tokens') { 
                            mintRoyaltyTokens() ;
                        }else if(mintBtnText==='Mint Complete'){
                            // setTimeout(() => {
                                openInNewTab(`${CONTRACT_OPENSEA_LINK}/${user.get('ethAddress')}`)
                                setMintBtnText('Mint Royalty Tokens');
                                setShowPopUp(false);
                                closePopUp(false);
                                // logout();  
                            // }, 2000);
                         }
                        //  else if(mintBtnText==='Sold Out'){
                        //         // openInNewTab(CONTRACT_OPENSEA_LINK)
                        //  };
                    }}>
                    <Text mintBtnText={mintBtnText}>{mintBtnText}</Text>         
                </MintBtnContainer>
                </MintWrap>
                
                </> :<></>
    }            
            
        </Wrapper>
    </Container>
    </BG>
  )
}

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}


const BG = styled.div`
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 100;


`


const Container = styled.div `
    position: relative;
    /* top: 10%;
    left: 50%; */
    /* transform: translate(-50%,0); */
    margin : 10% auto;
    background-color: rgba(0,0,0,.9);
    width: 85%;
    /* height: 90%; */
    z-index: 20;
    max-width: 700px;
    /* max-height: 250px; */
    animation: grow 1s 1;
    padding: 1rem; 
    padding-top: 55px;
    @media screen and (max-width:576px ) {
        padding-top: 50px;
    }

    animation: grow 1s 1;

 ${({showPopUp})=> ((!showPopUp)? `
         animation: shrink 1s 1;
    ` : "")}
   
    @keyframes shrink {    
        0% { opacity: 1; clip-path: inset(0); transform: scale(1)  }
        100% {  opacity: 0; clip-path: inset(5%); transform: scale(0%) }
    }

    @keyframes grow {
         0% { opacity: 0; clip-path: inset(5%); transform: scale(111.11%) }
        100% { opacity: 1; clip-path: inset(0); transform: scale(1) }
    }

    @media screen and (max-width:576px ) {
        margin : 15% auto;
	   
    }




`

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
`

const CloseBtn = styled.img`
    width: 30px;
	height: 30px;
    position: absolute; 
	top: 0px;
	right: 0px;
    margin: 1rem;
    z-index: 50;
    opacity: .75;
    transition: opacity 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &:hover{
    cursor: pointer;
  }

  @media screen and (max-width:576px ) {
       width: 25px;
	    height: 25px;
    }
  
`


const Heading = styled.h1`
    /* text-transform: uppercase; */
    /* font-weight: 800; */
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1rem;
    color: white;



    &:nth-child(3){
        margin-top: 20px;
        margin-bottom: 10px;
        /* background-color: red; */
    }
   


    @media screen and (max-width: ${props => props.theme.breakpoints.sm}){
      font-size: .8rem;
    }
    
`


const MintBtnContainer = styled.div`
    margin-top: ${({isComplete})=>(isComplete ? '0rem':'1.5rem')};;;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 260px;
    min-width: 220px;
   
    /* height: 100%; */
    /* border: 2px solid transparent; */
    border: 2px solid white;
    background-color: rgba(0,0,0,.55);
    /* background-color: transparent; */

    &:hover {
        cursor: pointer;
        border: 2px solid white;
        background-color: rgba(255,255,255,.25);
         -webkit-backdrop-filter: blur(2px);
         backdrop-filter: blur(2px);
  
         /* opacity: 1; */
    } 

    /* @media screen and (max-width: 1250px) {
          height: ${({isComplete})=>(isComplete ? '':'2rem')};;
    } */


   
`

const Text = styled.h2`
    margin: .5rem 1.5rem;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;

      @media screen and (max-width: 1250px) {
         font-size: 1rem;
    }

   /* ${({mintBtnText})=>(mintBtnText==='Processing...'? `
        display:inline-block;
        clip-path: inset(0  .85rem 0 0);
        animation: l 1s steps(4) infinite;

        @keyframes l {
            to {
                clip-path: inset(0 -.25rem 0 0)
            }
        }
   /* `:'')}  */


`






const BtnGroup = styled.div`
    /* margin-top: .5rem; */
    display: ${({isComplete})=>(isComplete ? 'none': 'flex')};
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    /* background-color: teal; */
    width: 9.5rem;
    -webkit-filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));

    @media screen and (max-width: 1250px) {
        height: 2rem;
        width: 7.5rem;
        
    }

`

const BtnBox = styled.div`
    height: 2.5rem;
    width: 2.5rem;
    border: 2px solid white;
    background-color: rgba(0,0,0,.55);
    display: grid;
    place-items: center;
    /* background-color: transparent; */

        &:hover {
        cursor: pointer;
        border: 2px solid white;
        background-color: rgba(0,0,0,.85);
         opacity: 1;
    }


     @media screen and (max-width: 1250px) {
          height:2rem;
          width: 2rem;
    }

`

const TextBox = styled.div`
    height: 100%;
    display: grid;
    place-items: center;
    /* background-color: blue; */
  
`


const NumText = styled.h1`
    height: 100%;

    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    color: #fff;
    text-decoration: none;
    font-size: 2.5rem;
    text-align: center;
    text-transform: uppercase;
    /* background-color: red; */
     /* -webkit-text-stroke: 2px black; */
  
    @media screen and (max-width: 1250px) {
        font-size: 1.75rem;
        
    }


`



const BtnText = styled.h2`
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;

     @media screen and (max-width: 1250px) {
         font-size: 1rem;
    }
`

const MintWrap = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`