import React, {useEffect, useState} from 'react';
import {FaBars} from 'react-icons/fa';
import {FaTimes} from 'react-icons/fa';
import { Link as LinkS } from 'react-scroll';
import styled from 'styled-components';
import { isDesktop, isMobile  } from 'react-device-detect';
import SidebarNew  from './sidebar2';
import {WhitelistModal}  from './whitelistModal';
import {RoyaltyModal}  from './royaltyModal';

import { useCookies } from 'react-cookie';
import  MintBtn from './mintBtn'
import { useMoralis} from "react-moralis";
import Web3 from "web3";

import GATcontractAbi from '../GAT.json';
import royaltyContractAbi from '../GAT_Royalty.json';

const MINT_TIME = process.env.REACT_APP_MINT_DATE;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

function asyncCall(time) {
  return new Promise((resolve) => setTimeout(() => resolve(), time));//1500
}


const Navbar = ( {mintDashCall,comicDashCall,isDesktopBG, toggleMint,isPageLoading, soldOut,toggleWLMint,setMintPrice}) => {

    const { user,authenticate, isAuthenticated, logout, isAuthenticating, Moralis } = useMoralis();

    //const web3Provider = Moralis.enableWeb3();
    const [scrollNav, setScrollNav] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const [showWLpopup, setShowWLpopup] = useState(false);
    const [showRoyaltyPopup, setRoyaltyPopup] = useState(false);

    const [status, setStatus] = useState([''])
    const [mintBtnText, setMintBtnText] = useState(``)
    const [cookies, setCookie, removeCookie] = useCookies();
    const [isTime, setIsTime] = useState(false);

    const [royaltyLabel, setRoyaltyLabel] = useState('Redeem royalty token');
    const [whitelistLabel, setWhitelistLabel] = useState('check whitelist');

    const [royaltyTokens, setRoyaltyTokens] = useState([]);
    // const [soldOut, setsoldOut] = useState(false);
    


    useEffect(()=>{
        // console.log(isMobile)

        // check time every sec until mint time
        const interval = setInterval(()=>{
            if (new Date(MINT_TIME) < (new Date())){
                setIsTime(true)
                clearInterval(interval)
            }
        }, 1000)
        return () => clearInterval(interval)
    },[])

    useEffect(() => {
        let text = isMobile ? 'Connect with MetaMask': 'Connect Wallet';
        setMintBtnText(`${isAuthenticated ? 'Wallet Connected' : text}`)
      
    },[isAuthenticated])

    const changeNav = () => {
        if((isDesktopBG && window.scrollY >= 30) || (!isDesktopBG &&  window.scrollY >= 110 )){
            setScrollNav(true);
        }else{
             setScrollNav(false);
        }
    }

    // sidebare logic
    const toggleNav = (event) => {
      if (isOpen){
          // console.log('close nav')
         setIsOpen(false);
      }else{
        // console.log('open nav')
         setIsOpen(true);
      }
       event.stopPropagation();
    }

    //close btn
    const closeRoyaltyPopUp= ()=>{
      setTimeout(() => { 
        setRoyaltyPopup(false) 
        setStatus(false);

    
    }, 900);
    }

    //close btn
    const closeWhitelistPopUp= ()=>{
      setTimeout(() => { 
        setShowWLpopup(false) 
    setStatus(false);
    }, 900);
    }


    const displayMessage = (msgs)=>{
        setStatus(msgs);
        setRoyaltyPopup(true);    
                                   
    }

    useEffect(() => {
        changeNav();
        window.addEventListener('resize',()=>{
            changeNav();
            setIsOpen(false);
        });
        window.addEventListener('scroll', ()=>{
            changeNav();
            setIsOpen(false);
        });
    },[])

    // check for royalty token
    const royaltyRedeem = ()=>{
         if(isAuthenticated){
            // displayMessage([]);
            setRoyaltyLabel('Checking royalty...');

            const web3Js = new Web3(Moralis.provider)

            let randomGeneratedHex = web3Js.utils.randomHex(32);

            let authData = user.get('authData');   
            let objectID = user.id;   
            // console.log(objectID)        
            let authDataSiignature = authData.moralisEth.signature;

            if(user.get('ethAddress')){

                let controller = new AbortController();
                let timeoutId = setTimeout(() => controller.abort(), 3000000);

                fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/getRoyaltyTokens?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}`,
                {   
                    signal: controller.signal,
                    headers:{ 'x-authenticate': authDataSiignature}})
                    .then(response =>response.json())
                    .then(data => {  
                        clearTimeout(timeoutId);       
                        // console.log(data)

                        if(data.value){
                            const neeededTokens = data.result.data;

                            if( neeededTokens.length == 0){
                                console.log('no tokens to redeem')
                                displayMessage(['This wallet has no royalty tokens to redeem.']);
                                

                            }else{
                                setRoyaltyTokens(neeededTokens);
                                displayMessage([`Congratulations! You are eligible to mint ${neeededTokens.length} free royalty tokens.`,'',`GAT Token Ids to Redeem: ${neeededTokens}`])
                                console.log(`tokens to redeem: ${neeededTokens}`)

                            }
                        }else{
                            displayMessage(['Error Occurred'])
                        }
                        setRoyaltyLabel('Redeem royalty token');
                    })
            }


        }else{
            displayMessage(['Royalty tokens give access to holder rewards and can be redeemed via free mint for each Gods & TItans token in a wallet.','To see if you are eligible to redeem a free Royalty token, connect your wallet and try again.'])

        }
    }


    const checkEligibility = ()=> {

        // check if mint time
        // if(!isTime){
        //     setStatus(['The Gods & Titans final Genesis mint (2nd set of 10 characters) will launch on November 10th at 8 PM EST.','The mint 2.0 minting mechanic will be in effect, view the announcements channel on the Gods & Titans discord for more information.']);
        //     setShowWLpopup(true);
        // }
        // // only check if metamask installed
        // else 
        
        if(window.ethereum && window.ethereum.isMetaMask){
        
            const web3Js = new Web3(Moralis.provider);
            let randomGeneratedHex = web3Js.utils.randomHex(32);
        
            try{
                // authenticates wallet
                if(!isAuthenticated){
                    setMintBtnText('Authenticating...')
                    authenticate({
                        provider: 'metamask',
                        signingMessage: randomGeneratedHex, 
                        chainId: CHAIN_ID,
                    })
                    .then( async(newUser) => {

                        let authData = newUser.get('authData');          
                        let authDataSiignature = authData.moralisEth.signature;
                        let objectID = newUser.id; 
                        console.log(newUser)  

                        if(newUser.get('ethAddress')){

                            // checks mint eligibility
                            setMintBtnText('Validating...')

                            if (isTime){
                                fetch(`${ process.env.REACT_APP_FUNCTION_DOMAIN}/getMintEligibility?address=${newUser.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}`,
                                {headers:{ 'x-authenticate': authDataSiignature}}
                                )
                                .then(response => response.json())
                                .then(data => {         
                                    console.log(data.result)
                                    setMintBtnText('Wallet Connected')
                                    setIsOpen(false);

                                    // shows pop up with eligibility status
                                    setStatus([data.result.status]);
                                    
                                    setShowWLpopup(true);

                                    // does action depeding on eligibility status
                                    if(!data.result.value){
                                        // logout()
                                        // setMintBtnText(isMobile ? 'Connect with MetaMask': 'Connect Wallet')
                                    }else{

                                        setMintPrice(data.result.price, data.result.amount)
                                        toggleMint(true);

                                        // ////////////// WL FREE MINT TOGGLE // ////////////// 
                                        // if (data.result.status == 'Eligible to mint, whitelisted wallet and has yet to mint.'){
                                            // toggleWLMint()
                                        // }
                                    }  
                                }).catch(function (error) {
                                    console.log(error);
                                    logout();
                                    setMintBtnText(isMobile ? 'Connect with MetaMask': 'Connect Wallet')
                                })  
                            } else{
                                setMintBtnText('Wallet Connected')
                                setIsOpen(false);
                            }         
                            }   
                        }).catch(()=>{
                            logout();
                            setMintBtnText(isMobile ? 'Connect with MetaMask': 'Connect Wallet')
                        })
                }   
                else {
                    logout();
                    toggleMint(false);
                    setMintBtnText(isMobile ? 'Connect with MetaMask': 'Connect Wallet')
                }   
            }catch(error) {
                console.log(error);
                logout();
                toggleMint(false);
                setMintBtnText(isMobile ? 'Connect with MetaMask': 'Connect Wallet')
            }; 

        }
        // try going to metamask if mobile
        else if( isMobile){
            window.open("https://metamask.app.link/dapp/godsandtitans.io");
        }
        else{
             // shows pop up with no metamask message
            setStatus(['Metamask not detected.']);
            
            setShowWLpopup(true);

        }
    };


    // const whitelistLogic = () =>{

    //     const whitelistFetch = (fetchUser) => {
    //         const web3Js = new Web3(Moralis.provider);
    //         let randomGeneratedHex = web3Js.utils.randomHex(32);
    //         let authData = fetchUser.get('authData');          
    //         let authDataSiignature = authData.moralisEth.signature;
    //         let objectID = fetchUser.id; 

    //         try{
    //             if(!cookies[fetchUser.get('ethAddress')]){
    //                 // console.log('no cookies')

    //                     // checks mint eligibility
    //                 setWhitelistLabel('Validating...')
                
    //                 fetch(`${ process.env.REACT_APP_FUNCTION_DOMAIN}/validateBluechipWL?address=${fetchUser.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}`,
    //                 {headers:{ 'x-authenticate': authDataSiignature}})
    //                     .then(response => response.json())
    //                     .then(data => {         
    //                         console.log(data.result)

    //                         setStatus([data.result.status, data.result.status_sub]);
    //                         setShowWLpopup(true);
    //                         setIsOpen(false);
    //                         setWhitelistLabel('check whitelist');
    //                         toggleWLMint();

    //                         if(data.result.value){
    //                             // setCookie(testAddress, 'true', { path: '/' });
    //                             // console.log('set cookies');
    //                             setCookie(fetchUser.get('ethAddress'), 'true', { path: '/' });
    //                         }

    //                         setWhitelistLabel('check whitelist');

    //                         // logout();
    //                     });
    //             } else {
    //                 // console.log('cookies found');
    //                 setStatus(['You already hold a Pre-Sale Spot under the Quality Collector Advantage.', null]);
    //                 setShowWLpopup(true);
    //                 setIsOpen(false);
    //                 setWhitelistLabel('check whitelist')
    //             }   
    //         } catch(e){
    //             console.log(e);
    //             logout();
    //             toggleMint(false);
    //             setIsOpen(false);
    //             setMintBtnText('Connect Wallet');
    //             setWhitelistLabel('check whitelist');
    //         }

    //     }
        
    //     // only check if metamask installed
    //     if(window.ethereum){

    //         if(!isAuthenticated){
    //             WLmessage();
    //         }   
    //         else {
    //             whitelistFetch(user) ;  
    //         } 
             
    //     }else{
    //         // shows pop up with no metamask message
    //         WLmessage(); 
    //     }
    // }


     const WLmessage = ()=>{
        setStatus(['We have created a \'Quality Collector Advantage\' to help ensure we attract quality investors into the project. This means that if you’re a collector that currently holds an NFT in one of the top 50 NFT projects then you will automatically be able to secure a spot on the Tier 1 pre-sales list.', 'Connect your wallet to check your eligibility or view the whitepaper for more information.']);
        setShowWLpopup(true);
        setIsOpen(false);
                                  
    }



  return ( <>
        {showWLpopup && <WhitelistModal closePopUp={closeWhitelistPopUp} status={status}/>}
        {showRoyaltyPopup && <RoyaltyModal closePopUp={closeRoyaltyPopUp} status={status} royaltyTokens={royaltyTokens}/>}


   
        <Nav scrollNav = {scrollNav}>
            <NavbarContainer scrollNav = {scrollNav}>

                {/* horizontal items */}
                <NavMenu scrollNav = {scrollNav}>
                
                    {/* whitepaper */}
                    <NavItem >
                            <NavLinkA href="./whitepaper.pdf" target={'true'}>
                              {window.innerWidth < 940?'white paper':'whitepaper'}
                            </NavLinkA>
                        </NavItem>

                    {/* comic studio */}
                        <NavItem >
                            <NavLinks
                                to={'cloud'} 
                                smooth={true} 
                                duration={500} 
                                spy={true} 
                                exact={'true'} 
                                activeClass='active' 
                                 onClick={()=>{
                                //   toggleNav();
                                  setTimeout(() => {  
                                    comicDashCall();
                                    }, 500);
                                  }}>
                               comic studio
                            </NavLinks>
                        </NavItem>

                    {/* the vault */}
                        <NavItem>
                            <NavLinks 
                                to={'acropolis'} 
                                smooth={true} 
                                duration={500} 
                                spy={true} 
                                exact={'true'} 
                                onClick={()=>{
                                //   toggleNav();
                                  setTimeout(() => {  
                                    mintDashCall(null,true);
                                    }, 500);
                                  }} >
                                the vault
                            </NavLinks>
                        </NavItem>

                    {/* Redeem royalty */}
                        <NavItem >
                            <NavLinkWL
                                onClick={()=>{
                                   royaltyRedeem()

                                }} >
                              {royaltyLabel}
                            </NavLinkWL>
                        </NavItem>

                     {/* whitelist logic */}
                        {/* <NavItem >
                            <NavLinkWL
                                 onClick={whitelistLogic} >
                              {whitelistLabel}
                            </NavLinkWL>
                        </NavItem> */}
                </NavMenu>

                 {window.innerWidth < 770 ? 
                    <>
                    <span></span>
                    {/* <MintDateWrapper scrollNav = {scrollNav}> 
                    mint price: 0.075 ETH<br/> */}
                        {/* {canMint && isDesktop ? <MintBtnWrapper><MintBtn toggleMint={toggleMint} canMint={canMint} style={{marginTop: 0}}>Mint</MintBtn></MintBtnWrapper> :  */}
                                               
                    {/* </MintDateWrapper> */}

                         <SidebarNew 
                            mintDashCall={mintDashCall}  
                            comicDashCall={comicDashCall}
                            checkEligibility={checkEligibility}
                             mintBtnText={mintBtnText}
                             soldOut={soldOut}
                             WLmessage={displayMessage}
                             toggleNav={toggleNav}
                             isOpened={isOpen}
                             setIsOpen={setIsOpen}
                             royaltyRedeem={royaltyRedeem}
                             royaltyLabel={royaltyLabel}
                            //  whitelistLogic={whitelistLogic}
                             whitelistLabel={whitelistLabel}
                            />
                           
                    </>
                        :<></>
                }
             
                  <NavBtn>
                    {!scrollNav ?  null
                    // {/* <NavLinks to="" onClick={(e)=>{
                    //         e.preventDefault();
                    //         // if(!soldOut){
                    //             // whitelistLogic()
                    //             checkEligibility()
                    //         // }
                           
                    //     }}> {mintBtnText} </NavLinks> */}
                        
                        :  <SidebarNew 
                            mintDashCall={mintDashCall}  
                            comicDashCall={comicDashCall}
                            checkEligibility={checkEligibility}
                            mintBtnText={mintBtnText}
                            soldOut={soldOut}
                             WLmessage={displayMessage}
                             toggleNav={toggleNav}
                             isOpened={isOpen}
                             setIsOpen={setIsOpen}
                              royaltyRedeem={royaltyRedeem}
                              royaltyLabel={royaltyLabel}
                            //   whitelistLogic={whitelistLogic}
                            whitelistLabel={whitelistLabel}
                            />}
                </NavBtn>

              
                   


                {/* {isDesktop  ? 
  
                <></>
 
               : <MintDateWrapperMobile scrollNav = {scrollNav}>Mint date: Tba</MintDateWrapperMobile>}
                {isDesktop && !isDesktopBG ? <MintDateWrapper scrollNav = {scrollNav}>Mint date: Tba</MintDateWrapper>: ''} */}
            </NavbarContainer>
        </Nav>
        
                 {/* </IconContext.Provider> */}
    </>
   
  )
}

export default Navbar

const Nav = styled.nav`
  
    background: ${({scrollNav}) => (scrollNav ? 'transparent' : 'transparent')};
    height: 80px;
    width: 100%;
    /* margin-top: -80px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: fixed;
    top:0;
    z-index: 10;
    /* background-color: rgba(5,5,5,.5); */

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }

`

const NavbarContainer = styled.div`
    display: flex;
    justify-content:${({scrollNav}) => (scrollNav ? 'flex-end' : 'space-between')};
    align-items: center;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    /* background-color: blue; */

    /* @media screen and (max-width: 825px){
         justify-content: flex-end;
    } */

      @media screen and (max-width: 768px){
         justify-content: space-between;
    }


`


const BtnGroup = styled.div`
display: flex;
justify-content: space-around;
align-items: center;
justify-self: flex-start;
opacity: ${({scrollNav}) => (scrollNav ? '0' : '1')};
transition: opacity .0s ease-in;
/* background-color: red; */

  @media screen and (max-width: 770px){
        display: none;
    }

    

`

const BtnWrapper = styled.a`
    display:flex;   
    width: 3.1rem;
    height: 3.1rem;
    border-radius: 100%;
    justify-content: center;
    align-items: center;  
    margin: 0 .3rem;

    &:hover{
        background-color: white;
            
    }

        @media screen and (max-width: 770px){
        order: 1;
    }

`

const BtnImg = styled.img`
  width: 100%;
  -webkit-filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));
   border-radius: 100%;
  /* box-shadow:; */
  /* padding: 0.5rem; */
  /* height: 50%; */

  &:hover{
      cursor: pointer;
  }
`



export const NavMenu = styled.ul`
    opacity: ${({scrollNav}) => (scrollNav ? '0' : '1')};
    transition: opacity .0s ease-in;

    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    /* margin-right: -22px; can be changed */
    /* height: 100%; */
    /* background-color: red; */

    @media screen and (max-width: 771px) {
        display: none;
    }
`

export const NavItem = styled.li`
    /* height: 80px; */
    /* background-color: blue; */
    display: flex;
    align-items: center;

`

export const NavLinks = styled(LinkS)`
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    color: #fff;
    text-decoration: none;
    font-size: .75rem;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: .9rem 1rem;
    /* height: 100%; */
    border: 2px solid transparent;
    background-color: rgba(0,0,0,.45);
    opacity: .95;
    /* background-color: transparent; */
  @media screen and (max-width: 970px){
       font-size: .75rem;
    }

    &:hover {
        cursor: pointer;
        border: 2px solid white;
        background-color: rgba(0,0,0,.85);
         opacity: 1;
    }
    `


export const NavLinkA = styled.a`
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    color: #fff;
    font-size: .75rem;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: .9rem 1rem;
    /* height: 100%; */
    border: 2px solid transparent;
    background-color: rgba(0,0,0,.45);
    opacity: .95;
    /* background-color: transparent; */


    &:hover {
        cursor: pointer;
        border: 2px solid white;
        background-color: rgba(0,0,0,.85);
         opacity: 1;
    }

     @media screen and (max-width: 970px){
       font-size: .75rem;
    }
    `


export const NavLinkWL = styled.div`
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    color: #fff;
    font-size: .75rem;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: .9rem 1rem;
    /* height: 100%; */
    border: 2px solid transparent;
    background-color: rgba(0,0,0,.45);
    opacity: .95;
    /* background-color: transparent; */


    &:hover {
        cursor: pointer;
        border: 2px solid white;
        background-color: rgba(0,0,0,.85);
         opacity: 1;
    }

      @media screen and (max-width: 970px){
       font-size: .75rem;
    }
    `
    

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px){
        display: none;
    }
`


export const NavBtnLink = styled(LinkS)`
border-radius: 50px;
background: #01bf71;
white-space: nowrap;
padding: 10px 22px;
color: #010606;
font-size: 16px;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
}
`

const Icon = styled.div`
   position: absolute;
   top: 1.2rem;
   right: 1.5rem;
   background: transparent;
   font-size: 2rem;
   cursor: pointer;
   outline: none;
`

const CloseIcon = styled(FaTimes)`
    color: #fff;
`

const NavHamburger = styled.div`
    font-size: 1.8rem;
    cursor: pointer;
    -webkit-filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));
    display: block;
  

    @media screen and (max-width: 770px) {
        order: 2;
        
    }
`


const HamIcon = styled(FaBars)`
  color: #FFF;

  &:hover{
      color: #883B1D;
  }

`

const MintDateWrapperMobile = styled.div`
    display: ${({scrollNav}) => (scrollNav ? 'none' : 'flex')};
    align-items: center;
    transition: opacity .15s ease-in;
    text-transform: uppercase;
    color: white;
    font-weight: bolder;
    font-size: 1rem;
    /* margin-top: -80px;
    position: absolute;
    top: 0;
    left: 0; */

    opacity: ${({scrollNav}) => (scrollNav ? '0' : '1')};

     /* @media screen and (min-width: 770px){
        position: absolute;
        right: 26.5%;
    } */

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}){
        font-size: 1.4rem;
    }

`




const MintDateWrapper = styled.div`
    /* display: ${({scrollNav}) => (scrollNav ? 'none' : 'flex')}; */
    align-self: center;
    transition: opacity .15s ease-in;
    text-transform: uppercase;
    color: white;
    font-weight: bolder;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    /* background-color: aliceblue; */

    /* position: sticky; */
    /* top: 80px; */
    /* left: 0; */
    z-index: 10;
    /* padding: 0 24px; */
    height: 20px;
    /* margin-top: -20px; */

    opacity: ${({scrollNav}) => (scrollNav ? '0' : '1')};

     /* @media screen and (min-width: 770px){
        position: absolute;
        right: 26.5%;
    } */

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}){
        font-size: 1.4rem;
    }
`

const MintBtnWrapper = styled.div`
    height: 100%;
    margin-top: -.5rem;
`








    // const whitelistLogic = () =>{
        

    //      // only check if metamask installed
    //     if(window.ethereum){
    //         const web3Js = new Web3(Moralis.provider);
    //         let randomGeneratedHex = web3Js.utils.randomHex(32);
    //         // console.log("random number hex", randomGeneratedHex);

    //         try{

    //             if(!isAuthenticated){
    //                 setMintBtnText('Authenticating...')
    //                 authenticate({provider: 'metamask',signingMessage: randomGeneratedHex, chainId: CHAIN_ID})
    //                 .then( async(newUser) => {

    //                     let authData = newUser.get('authData');           
    //                     let authDataSiignature = authData.moralisEth.signature;
    //                     let objectID = newUser.id; 

    //                 //    if(testAddress){
    //                     if(newUser.get('ethAddress')){
    //                         // cookies WL check
    //                         // if(!cookies[testAddress]){
    //                         if(!cookies[newUser.get('ethAddress')]){
    //                             // console.log('no cookies')

    //                              // checks mint eligibility
    //                             setMintBtnText('Validating...')
                            
    //                             fetch(`${ process.env.REACT_APP_FUNCTION_DOMAIN}/validateBluechipWL?address=${newUser.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}`,
    //                             {headers:{ 'x-authenticate': authDataSiignature}})
    //                                 .then(response => response.json())
    //                                 .then(data => {         
    //                                     console.log(data.result)
            
    //                                     setStatus([data.result.status, data.result.status_sub]);
    //                                     setShowWLpopup(true);

    //                                     if(data.result.value){
    //                                         // setCookie(testAddress, 'true', { path: '/' });
    //                                         // console.log('set cookies');
    //                                         setCookie(newUser.get('ethAddress'), 'true', { path: '/' });
    //                                     }

    //                                     logout();
    //                                 });
    //                         } else {
    //                             // console.log('cookies found');
    //                             setStatus(['You already hold a Pre-Sale Spot under the Quality Collector Advantage.', null]);
    //                             setShowWLpopup(true);
    //                             logout();
    //                         }                                        
    //                     }
    //                 })
    //                 .catch(function (error) {
    //                     console.log(error);
    //                      logout();
    //                     toggleMint(false);
    //                     setMintBtnText(isMobile ? 'Connect with MetaMask': 'Connect Wallet')
    //                 });
    //             }   
    //             else {
    //                 logout();
    //                 toggleMint(false);
    //                 setMintBtnText(isMobile ? 'Connect with MetaMask': 'Connect Wallet')
    //             } 
    //         } catch(e){
    //             console.log(e);
    //             logout();
    //             toggleMint(false);
    //             setMintBtnText(isMobile ? 'Connect with MetaMask': 'Connect Wallet')
    //         }     
    //     }else{
    //         // shows pop up with no metamask message
    //         setStatus(['Metamask not detected.']);
            
    //         setShowWLpopup(true);
    //     }
    // }



    // useEffect(()=>{

    //   if (!isPageLoading)
    //   {
    //     // console.log('loaded')
    //     async function loadUp(){
    //         // await asyncCall(1500);
    //         const CONTRACT_ADDRESS = process.env.REACT_APP_GAT_MINT_ADDRESS;
    //         const web3Js = new Web3(Moralis.provider);
    //         const contract = new web3Js.eth.Contract(GATcontractAbi.abi, CONTRACT_ADDRESS);
    //         const totalSupply = await contract.methods.totalSupply().call();
    //         const maxSupply = await contract.methods.maxSupply().call();
    //         // const [totalSupply, maxSupply] = await Promise.all([,]);
    //         // console.log(`${totalSupply}/${maxSupply}`)

    //         // // set sold out if total supply matches max
    //         if(totalSupply == maxSupply){
    //             setsoldOut(true)
    //         }
    //     } 

    //     if(window.ethereum){
    //       loadUp();
    //     }}
    // },[isPageLoading])