import React, { useEffect, useState,useReducer } from 'react';
import { YTDbox} from '../ytdBox';
import { ActivityBox } from '../activityBox';
import { DistributedBox} from '../distributedBox';
import { CollectionBox} from '../collectionBox';
import { useMoralis} from "react-moralis"
import Web3 from "web3";
import  { db }  from "../../../App";
import { addDoc, collection, query, where, getDocs,doc } from "firebase/firestore";
import { useCookies } from 'react-cookie';
import GATcontractAbi from '../../GAT.json';
import royaltyContractAbi from '../../GAT_Royalty.json';
import companionContractAbi from '../../GAT_Companion.json';

import {WhitelistModal}  from '../../gat_components/whitelistModal';


import {reducer, defaultState} from './dashReducer';


import {
    DashContainer,
    DashWrapper,
    DashRow,
    DashDataColumn,
    YTDrow,
    DistributeRow,
    BtnWrapper,
    DashCollectColumn,
    Button,
    RefreshContainer,
    Refresh,
    RefreshWrap

} from './dashboardElements';


const MINT_TIME = process.env.REACT_APP_MINT_DATE;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID


///////////// DASHBOARD CONSTANTS ///////////// 
const ROYALTY_ADDRESS = process.env.REACT_APP_ROYALTY_ADDRESS
const COMPANION_ADDRESS = process.env.REACT_APP_GAT_COMPANION_ADDRESS

const GAT_ADDRESS =  process.env.REACT_APP_GAT_MINT_ADDRESS;
const MORALIS_URL = 'https://deep-index.moralis.io/api/v2/';
const NETWORK = process.env.REACT_APP_NETWORK;



///////////// DASHBOARD HELPER TEXT ///////////// 
let helpBelowRoyaltyFloor = <>Unfortunately no royalties were earned because this NFT sale sold on OpenSea for under the Royalty Floor amount - (original mint price + 10%). Only sales made above the Royalty floor are eligible for you to earn royalty earnings on.</>
let helpRoyaltyCollect = <>This balance shows the current amount of royalties that you have available to withdraw to your wallet. Press collect to action your withdrawal.</>
let helpDividendCollect = <>This balance shows the current amount of loyalty rewards that you have available to withdraw to your wallet. Press collect to action your withdrawal.</>
let helpRoyaltyDistributed = <>This is the current total amount of royalties paid out to collectors across the entire NFT project. </>
let helpDividendDistributed = <>This is the current total amount of loyalty rewards paid out to collectors across the entire NFT project. </>


export const DashBoard = () => {

    const [state, dispatch] = useReducer(reducer,defaultState);
    const [walletBtnText, setWalletBtnText ] = useState('Connect Wallet');
    const [cookies, setCookie, removeCookie] = useCookies();
    const { authenticate, isAuthenticated, logout, isAuthenticating, Moralis,  user} = useMoralis();

    const [showPopup, setShowPopup] = useState(false);
    const [status, setStatus] = useState([''])
    // const logOutOnAccountChange = Moralis.onAccountChanged(async (accounts) => { 
    //     logout();
    // }); 

    useEffect(()=>{


        logout();  
        // console.log(state)

        if(window.ethereum){

            // disconnect on account change
        window.ethereum.on('accountsChanged', (accounts) => {
                reset()
                // console.log(accounts)
            })
        }


        //  setTimeout(() => { setShowPopup(true) }, 2000);

    },[])


    ///////  reset values on error  //////////
    const reset = (isError) => {
        logout();
        setWalletBtnText('Connect Wallet');
        dispatch({type: 'RESET_DASH_VALUES'});

        if(isError){
            setStatus(['Error Occured.']);
            setShowPopup(true);
        }
    }


    ///////  connect wallet to vault  //////////
    const checkVaultAccess = () =>  {
        const web3Js = new Web3(Web3.givenProvider); 
         let randomGeneratedHex = web3Js.utils.randomHex(32);
       
    
        // authenticates wallet
        if(!isAuthenticated){
            setWalletBtnText('Loading...')
            authenticate({provider: 'metamask',signingMessage: randomGeneratedHex, chainId: CHAIN_ID})
            .then(async (newUser) =>{

                let userWallet = newUser.get('ethAddress');
                let authData = newUser.get('authData');   
                let objectID = newUser.id;   
                // console.log(objectID)        
                let authDataSiignature = authData.moralisEth.signature; 

                if(userWallet){

                    // checks mint eligibility
                    setWalletBtnText('Validating...');

                    // see if wallet has minted tokens before
                    const contract = new web3Js.eth.Contract(companionContractAbi.abi, COMPANION_ADDRESS);
                    const mintedTokens = await contract.methods.getMintAddresses(userWallet).call();
                    // console.log(mintedTokens)    

                    // minted tokens found
                    if (mintedTokens.length > 0){

                        setWalletBtnText('Wallet Connected')

                         // update analytics log
                        let resultInfo = {collection_name : 'connect_to_vault', status: 'pass', condition: 'owned royalty tokens'}
                        let stringResult = encodeURIComponent(JSON.stringify(resultInfo));
                        fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/analyticsUpdate?address=${userWallet}&nonce=${randomGeneratedHex}&objectid=${objectID}&resultinfo=${stringResult}`,
                            {headers:{ 'x-authenticate': authDataSiignature}}).catch(e=>{
                            console.log(e)
                             reset(true);
                        })
                        

                        // check local storage for saved values
                        if(cookies[`vault_data_${userWallet}`] && process.env.REACT_APP_SAVE_LOCAL_DASH == 'true'){
                            
                            dispatch({type: 'SET_DASH_VALUES', 
                                    currentInfo: cookies[`vault_data_${userWallet}`],
                                    currentCompanyInfo: cookies[`company_data_${userWallet}`],
                                    ethToDollar: cookies[`ethToDollar`]});

                        } else{
                            fillDashValues(newUser)
                        }
                    
                    // no tokens found for user wallet
                    } else{

                        setStatus(['Not eligible to unlock vault.']);
                        setShowPopup(true);
                        reset()

                        // update analytics log
                        let resultInfo = {collection_name : 'connect_to_vault', status: 'failed', condition: 'no royalty tokens owned'}
                        let stringResult = encodeURIComponent(JSON.stringify(resultInfo));
                        fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/analyticsUpdate?address=${userWallet}&nonce=${randomGeneratedHex}&objectid=${objectID}&resultinfo=${stringResult}`,
                            {headers:{ 'x-authenticate': authDataSiignature}}).catch(e=>{
                            console.log(e)
                             reset(true);
                             
                        })
                        
                    }
                }   
            })
            .catch(function (error) {
                console.log(error);
                 reset(true)

                // update analytics log
                // let resultInfo = {collection_name : 'connect_to_vault', status: 'failed', condition: error.message}
                // let stringResult = encodeURIComponent(JSON.stringify(resultInfo));
                // fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/analyticsUpdate?address=${userWallet}&nonce=${randomGeneratedHex}&objectid=${objectID}&resultinfo=${stringResult}`,
                //     {headers:{ 'x-authenticate': authDataSiignature}}).catch(e=>{
                //     console.log(e)
                //         reset();
                // })

            });
        }   
        else {
             reset()
        }    
    };


    /////// refresh dashboard app  //////////
    const fillDashValues = async (user, newTransactionMade) => {

        const web3Js = new Web3(Web3.givenProvider); 

        let randomGeneratedHex = web3Js.utils.randomHex(32);

        let userWallet = user.get('ethAddress')
       // check if user found
        if(userWallet){
            let authData = user.get('authData');  
            let objectID = user.id;   
            let authDataSiignature = authData.moralisEth.signature;

            dispatch({type: 'TOGGLE_LOADING', value: true});

            // date of last update
            let lastUpdate = cookies[`last_update_${userWallet}`];
            if (lastUpdate && !newTransactionMade){
                var diff = Math.abs(Date.now() - parseInt(lastUpdate));
                diff /= (1000 * 60 * 60 * 24);

                // use same data if less than 24hrs
                if(diff < 1 && process.env.REACT_APP_SAVE_LOCAL_DASH == 'true'){
                    console.log('use cookies');
                    console.log(cookies[`ethToDollar`]);
                    
                    dispatch({type: 'SET_DASH_VALUES', 
                            currentInfo: cookies[`vault_data_${userWallet}`],
                            currentCompanyInfo: cookies[`company_data_${userWallet}`],
                            ethToDollar: cookies[`ethToDollar`]});
                    
                    dispatch({type: 'TOGGLE_LOADING', value: false});

                    console.log('used cookies')
                    return
                }
            }


            // get transactions
            // console.log('update dash values')
            await fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/getTransactionData?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}`,
            {headers:{ 'x-authenticate': authDataSiignature}})
                .then(response => response.json())
                .then(data => {  

                    // console.log(data)

                    if(data.value){
                        // saved use data to local storage and display
                        var newData = {
                            nfts: data.result.transactionHistory, 
                            ytdEarning: data.result.withdrawnAmt,
                            royaltyToCollect: data.result.royaltyTotal,
                            royaltyToDisplay: data.result.royaltyDisplay
                        }
                   
                        //config for usd to eth
                        const options = {
                            address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
                            chain: "eth"
                        };

                        // get eth price and company values
                        Moralis.Web3API.token.getTokenPrice(options).then(async(priceResult)=>{
                            
                            const contract = new web3Js.eth.Contract(royaltyContractAbi.abi, ROYALTY_ADDRESS);
                            const royaltyDistFound = await contract.methods.getBalance().call();
                
                            const companyValues = { royaltyTotal:royaltyDistFound}
                
                            // set cookies of data
                            setCookie(`ethToDollar`, priceResult.usdPrice )
                            setCookie(`vault_data_${user.get('ethAddress')}`, newData)
                            setCookie(`last_update_${userWallet}`, new Date().getTime())
                            setCookie(`company_data_${user.get('ethAddress')}`, companyValues)
                           

                            // updates state and sets dashbaord values
                            dispatch({type: 'SET_DASH_VALUES', 
                                    currentInfo: newData,
                                    saltData: data.result.saltData,
                                    currentCompanyInfo: companyValues,
                                    ethToDollar: priceResult.usdPrice});

                            
                            dispatch({type: 'TOGGLE_LOADING', value: false}); 
                            if (newTransactionMade) dispatch({type: 'TOGGLE_WITHDRAW_PROCESS', value: 'Collect'});
                        }); 
                    }else{
                         reset(true)

                    }

                }).catch(e=>{

                    let errorData = {
                    //     walletAddress: user.get('ethAddress'),
                        error: e,
                        epochTime: new Date().getTime(),
                    }

                    let stringData = encodeURIComponent(JSON.stringify(errorData));
                    

                     fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/handleError?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&data=${stringData}`,
                        {headers:{ 'x-authenticate': authDataSiignature}})
                            .then(response => response.json())
                            .then(data => {
                                    reset(true)
                                });
                    console.log(e)
                })
        
        }    
    }

    /////// process withdraw  //////////
    const proccessWithdrawal = async () =>{
        dispatch({type: 'TOGGLE_WITHDRAW_PROCESS', value: 'Updating...'});


        let lastUpdate = cookies[`last_update_${user.get('ethAddress')}`];
        var diff = Math.abs(Date.now() - parseInt(lastUpdate));
        diff /= (1000 * 60 * 60 * 24);

        // use same data if less than 24hrs
        if(diff > 1 ){
            let r = await fillDashValues(user);
        }


        await new Promise((resolve) => setTimeout(() => resolve(), 500))
        // .then(r=>{
            const web3Js = new Web3(Web3.givenProvider); 
             
            let randomGeneratedHex = web3Js.utils.randomHex(32);
       
            let authData = user.get('authData');   
            let objectID = user.id;   
            // console.log(objectID)        
            let authDataSiignature = authData.moralisEth.signature;

            if(user.get('ethAddress')){

                // checks mint eligibility
                // console.log('process withdraw')
                dispatch({type: 'TOGGLE_WITHDRAW_PROCESS', value: 'Processing...'});

                
                try{
                    let token,daysLeft,salt;
                     // retrieve salt and token from storage
                    if( state.saltData ){
                        token = state.saltData.token;
                        salt = state.saltData.salt;
                        daysLeft = state.saltData.daysLeft;
                    }else{
                        let saltData = await fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/processWithdrawal?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&amount=${state.currentInfo.royaltyToDisplay}`,
                        {headers:{ 'x-authenticate': authDataSiignature}})
                        .then(response => response.json());
                        
                        console.log(saltData);

                        // retrieve salt and token from firebase function
                        if(saltData.value){
                            token = saltData.result.token;
                            salt = saltData.result.salt;
                        }else{
                            daysLeft = saltData.result.daysLeft;
                        }

                         dispatch({type: 'UPDATE_SALE', saltData: saltData.result});
                
                    }


   
 
                    // make contact with smart contract if salt & token found
                    if( salt && token && !daysLeft){ 
                        console.log(`Displayed royalty(with slippage fee): ${state.currentInfo.royaltyToDisplay}`)
                        console.log('salt and token found, call contract')
                        const contract = new web3Js.eth.Contract(royaltyContractAbi.abi, process.env.REACT_APP_ROYALTY_ADDRESS);
                        contract.methods.withdraw(state.currentInfo.royaltyToDisplay, user.get('ethAddress'), salt, token).send({from: user.get('ethAddress')}).then(result=>{
                            console.log(result)
                            setStatus(['Withdraw successful.']);
                            setShowPopup(true);

                            //  update dash with new values to finish processing
                            fillDashValues(user, true);
                           
                        }).catch((contractError)=>{

                            
                            // console.log(contractError)
                                let errorData = {
                            //     walletAddress: user.get('ethAddress'),
                                error: contractError,
                                epochTime: new Date().getTime(),
                            }

                            let stringData = encodeURIComponent(JSON.stringify(errorData));
                            
                            // console.log(stringData);

                            fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/handleError?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&data=${stringData}`,
                            {headers:{ 'x-authenticate': authDataSiignature}})
                                .then(response => response.json())
                                .then(data => {
                                    // reset(true)
                                 });

                            console.log(contractError)
                            console.log(contractError.code)
                            if (contractError.code !== 4001) {
                                reset(true)
                            }else{
                                dispatch({type: 'TOGGLE_WITHDRAW_PROCESS', value: 'Collect'});
                            }

                        })

                    } else if (daysLeft){
                        setStatus([`Can't withdraw for another ${daysLeft} days`]);
                        setShowPopup(true);

                        console.log(`Can't withdraw for another ${daysLeft} days`)
                        dispatch({type: 'TOGGLE_WITHDRAW_PROCESS', value: 'Collect'});
                    }
                    else{
                        console.log('salt and token retrieval error')
                        reset(true)

                    }               
                        // })
                    }catch(e){
                        
                        let errorData = {
                        //     walletAddress: user.get('ethAddress'),
                            error: e,
                            epochTime: new Date().getTime(),
                        }

                        let stringData = encodeURIComponent(JSON.stringify(errorData));
                        
                        // console.log(stringData);

                        fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/handleError?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&data=${stringData}`,
                        {headers:{ 'x-authenticate': authDataSiignature}})
                            .then(response => response.json())
                            .then(data => {});

                       reset(true)
                            

                    }
            }  
        // })
    }

    //close btn
    const closePopUp= ()=>{
      setTimeout(() => { setShowPopup(false) }, 900);
    }



  return (

    <>
     {showPopup && <WhitelistModal closePopUp={closePopUp} status={status} isVault={true}/>}
    

    
       <DashContainer>
           <DashWrapper>
                <DashRow>
                    <DashDataColumn>
                            <ActivityBox 
                                state = {state} 
                                helpText = {helpBelowRoyaltyFloor}
                                />

                            {/* <DistributeRow>
                              
                                {/* <DistributedBox 
                                        title='Loyalties distributed' 
                                        eth = {currentCompanyInfo.dividendsTotal}
                                        convertUSD = {ethToDollar}
                                        helpText = {helpDividendDistributed}
                                        order = {2}
                                        /> */}
                                {/* <DistributedBox 
                                        title='donations collected' 
                                        eth = {currentCompanyInfo.donationsTotal}
                                        convertUSD = {ethToDollar}
                                        helpText = {lispum}
                                        order = {3}
                                        /> */}
                            {/* </DistributeRow> */}

                    </DashDataColumn>

                    <DashCollectColumn>
                            <BtnWrapper>
                                <Button id='connect_wallet'
                                isAuthenticated={walletBtnText==='Wallet Connected'}     
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        if(window.ethereum && (new Date(MINT_TIME) < (new Date()))){
                                            if (walletBtnText !=='Loading...' && walletBtnText !=='Validating...')
                                            { checkVaultAccess(); }
                                        }else if(new Date(MINT_TIME) > (new Date())){
                                              setStatus(['The Vault will open once mint is live.']);
                                              setShowPopup(true);
                                        }else if(!window.ethereum){
                                            setStatus(['No Metamask detected.']);
                                              setShowPopup(true);
                                        }
                                    }}>

                                {walletBtnText}
                                </Button>

                                <RefreshContainer  
                                    isAuthenticated={walletBtnText==='Wallet Connected'}>
                                    <RefreshWrap  isLoading = {state.isLoading}>
                                    <Refresh 
                                       
                                        onClick={(e)=>{
                                        e.preventDefault();
                                        if(!state.isLoading)
                                          {  fillDashValues(user);}

                                            // setTimeout(() => {
                                                // let dataString = JSON.stringify(currentInfo)
                                                // setCookie(`vault_data_${user.get('ethAddress')}`, dataString)
                                                // console.log(dataString)
                                        // }, 3000)});
                                    }}/></RefreshWrap>
                                </RefreshContainer>

                            </BtnWrapper>
                            <YTDbox 
                                    title='ytd earnings' 
                                    state = {state}
                                    order = {1}
                                    />
                            
                            <CollectionBox
                                title='royalties to collect' 
                                state = {state}
                                // donationActive = {currentInfo.donationActiveRoyalty}
                                // donationPercent = {currentInfo.donationPercentRoyalty}
                                helpText = {helpRoyaltyCollect}
                                order = {1}
                                withdrawalCallback={proccessWithdrawal}
                            />

                              <DistributedBox 
                                        title='royalties distributed' 
                                        state = {state}
                                         helpText = {helpRoyaltyDistributed}
                                         order = {1}
                                        />
                            {/* <CollectionBox
                                title='Loyalty to collect' 
                                eth = {currentInfo.dividendToCollect}
                                convertUSD = {ethToDollar}
                                donationActive = {currentInfo.donationActiveDividend}
                                donationPercent = {currentInfo.donationPercentDividend}
                                helpText = {helpDividendCollect}
                                order = {2}
                                
                            /> */}
                    </DashCollectColumn>
                </DashRow>
            </DashWrapper>
       </DashContainer>
    </>   
  )
}