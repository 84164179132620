import React, {useState, useEffect} from 'react';
import { 
  CollectionContainer,
  CollectionWrapper,
  CollectionHeading,
  CollectionSection,
  SubtextWrapper,
  Subtext,
  DisplayBoxWrapper,
  DisplayBox,
  BtnWrapper,
  QuestionWrapper,
  EthLogoWrapper,
  EthAmount,
  DollarAmount,
  DonationSection,
  DonationHeading,
  DonationPercentWrapper,
  PercentAmount,
  Disabled,
  CheckboxWrapper,
  Checkbox,
  DropdownArrow,
  DonationText,
  CollectionButton
 } from './collectionElements';

//  import EthImgLogo from '../../../public/eth.svg'
// import Image from 'next/image';
 import { FaRegQuestionCircle} from 'react-icons/fa';
import { HelpBox } from '../HelpBox';
import { Transform } from '@mui/icons-material';

// converts wei to eth
const wei_to_eth = (wei) => {
    return  Math.round((wei * Math.pow(10,-18)) * process.env.REACT_APP_PLACEVALUE) /  process.env.REACT_APP_PLACEVALUE
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}



export const CollectionBox = (data) => {
  const {title, state, helpText, order, withdrawalCallback} = data;

  const eth = state.currentInfo.royaltyToDisplay;
  const convertUSD = parseInt(state.ethToDollar) ;
  const collectBtnText = state.collectBtnText;

  const dollarAmt = numberWithCommas(Math.round(wei_to_eth(eth) * convertUSD * 100) /100) ; 

  // const donationAmt = donationPercent * dollarAmt;

  const [style, setStyle] = useState({opacity: '0',transition: 'all .1s ease-in-out',zIndex:-20});
  
  return (
    <CollectionContainer imgId={order}>
  
        <CollectionWrapper>
        
          {/* header text */}
          <CollectionHeading>{title}</CollectionHeading>

           <div style={style}>
                <HelpBox text= {helpText} extraTop={true} />
              </div>

            {/* middle section */}
            <CollectionSection>
                

              {/* help section */}
              <SubtextWrapper 
                onMouseEnter={e => {
                     setStyle({opacity: '1', transition: 'all .1s ease-in-out'});
                 }}
                
                 onMouseLeave={e => {
                     setStyle({opacity: '0',transition: 'all .1s ease-in-out'})
                 }}
              > 
               
                  <Subtext>What's this</Subtext>

                  <QuestionWrapper>
                    <FaRegQuestionCircle/>
                  </QuestionWrapper>
                  
              </SubtextWrapper>

           

                  {/* eth amount display */}
                  <DisplayBoxWrapper>
                    <DisplayBox>
                      <EthLogoWrapper>
                        <img  src={'./eth.svg'}/>
                      </EthLogoWrapper>

                      <EthAmount style={{alignSelf: 'center'}}>{wei_to_eth(eth)}</EthAmount>
                      <DollarAmount >(${dollarAmt})</DollarAmount>
                    </DisplayBox>

                    <BtnWrapper>
                      
                      <CollectionButton ethValue={eth> 0} id={`${title.split(' ')[0]}_collectBtn`}
                        onClick={(e)=>{
                            e.preventDefault()
                            if (eth > 0 && collectBtnText == 'Collect'){
                               withdrawalCallback();
                            }
                           
                        }}
                      
                      > {collectBtnText}</CollectionButton>
                    </BtnWrapper>
                  </DisplayBoxWrapper>

                  {/* give donation section */}
                {/* <DonationSection>
                  <DonationHeading>Donation<br/>Amount</DonationHeading>
                   */}
                 {/* donation percent box */}
                  {/* <DonationPercentWrapper>
                      
                      <PercentAmount>{donationPercent * 100}% (${donationAmt})</PercentAmount>
                      <DropdownArrow/>
                  </DonationPercentWrapper> */}

                  {/* checkbox */}
                  {/* <CheckboxWrapper>
                      <Checkbox type="checkbox" id="donate-royalty"/>
                  </CheckboxWrapper>
      
              </DonationSection> */}

            </CollectionSection>

          {/* donation info lower section */}
             <DonationText>
               Withdraws can only be made every 30 days. The value displayed is after 5% deduction due to slippage fees.
            </DonationText> 
          

        </CollectionWrapper>
    </CollectionContainer>
  )
}


