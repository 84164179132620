

export const defaultState = {
    currentInfo: {
        ytdEarning: 0.0,
        royaltyToCollect: 0.0,
        royaltyToDisplay: 0.0,
        nfts: []
    },
    currentCompanyInfo : {
        royaltyTotal: 0.0,
    },
    ethToDollar: 0, 
    isLoading: false,
    saltData: {},
    collectBtnText: 'Collect'
}


  // gets image path given selected nfts
export const reducer = (state,action) => {

  // cycles through inventory
  if (action.type === 'SET_DASH_VALUES'){
  
      return {
      ...state,
      currentInfo: action.currentInfo,
      currentCompanyInfo: action.currentCompanyInfo,
      ethToDollar: action.ethToDollar,
      saltData: action.saltData
    }
  } else if (action.type === 'RESET_DASH_VALUES'){
  
    return {
      ...defaultState
    }
  } else if (action.type === 'TOGGLE_LOADING'){
  
      return {
      ...state,
     isLoading: action.value
    }
  }else if (action.type === 'TOGGLE_WITHDRAW_PROCESS'){
  
      return {
      ...state,
     collectBtnText: action.value
    }
  }else if (action.type === 'UPDATE_SALE'){
  
      return {
      ...state,
     saltData: action.saltData
    }
  }

  return new Error('no matching action type')
}