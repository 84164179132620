
import React,{useEffect} from 'react';
import styled from 'styled-components';
import { useMoralis} from "react-moralis"
;

export const Preloader = () => {
 const { Moralis } = useMoralis();

  useEffect(()=>{
    const loadMoralis = async()=>{
        // await Moralis.enableWeb3();
    }

      if(window.ethereum){
        loadMoralis();
        // console.log('metamask detected')
      }else{
        // console.log('No metamask detected')
      }
  },[])
   
  return (
      <div style={{background: '#6B5F6F', width: '100%', height: '100%'}}>
        


      <Wrapper>
         <BG src={'images/Loading_Screen_BG.jpg'}/>
        <Logo src={'images/logo.png'}/>
         <LoadingBox>
        <LoadingBar/>
        </LoadingBox>
      </Wrapper>

      </div>

  )
}


const BG = styled.img`
  position: absolute;
  margin: 0 auto;
  height:100Vh;
  width:100vw;
  object-fit: cover;
  left: 50%;
  transform: translateX(-50%);

  /* @media screen and (min-width: ${props => props.theme.breakpoints.xl}) {
      left: 0;
      transform: translateX(0);
      width:100vw;
     
    } */
`

const Wrapper = styled.div`
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
position: absolute;
flex-direction: column;
top: 0;
left: 0;
z-index: 50;
background-color: transparent;
`

const Logo = styled.img`
    width: 50%;
    min-width: 300px;
    max-width: 350px;
    z-index: 20;
`

const LoadingBox = styled.div`
    width: 50%;
    min-width: 200px;
    max-width: 300px;
    height: 20px;
    border: .2rem solid white;
    opacity: .8;

   
`
const LoadingBar = styled.div`
  width: 100%;
  height: 100%;
  background-color: #FFFFFF75;
  /* transition: width 1.5s ease; */
  animation: load 1.5s infinite;

  @keyframes load {
  0% {
    width: 0%;
  }
  30% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
`

