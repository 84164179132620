import React,{useEffect} from 'react';
// import EthImgLogo from '../../../public/eth.svg'
// import Image from 'next/image';
import { 
  YtdContainer,
  YtdWrapper,
  YtdHeading,
  YtdSubheadingWrap,
  EthLogoWrapper,
  EthAmount,
  DollarAmount,

} from './ytdElements';


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// converts wei to eth
const wei_to_eth = (wei) => {
    return  Math.round((wei * Math.pow(10,-18)) * process.env.REACT_APP_PLACEVALUE) /  process.env.REACT_APP_PLACEVALUE
}


export const YTDbox = (data) => { 
  const { title, state, order } = data;

  let eth = state.currentInfo.ytdEarning;
  let convertUSD = parseInt(state.ethToDollar) ;
  let dollarAmt = numberWithCommas(Math.round(wei_to_eth(eth) * convertUSD * 100) /100) ;



  return (
    <YtdContainer imgId={order}>
      <YtdWrapper>
        <YtdHeading>{title}</YtdHeading>

        <YtdSubheadingWrap>
          <EthLogoWrapper>
            <img  src={'./eth.svg'}/>
          </EthLogoWrapper>
          <EthAmount>{wei_to_eth(eth)}</EthAmount>
          <DollarAmount>(${dollarAmt})</DollarAmount>

        </YtdSubheadingWrap>
      </YtdWrapper>
    </YtdContainer>
  )
}
