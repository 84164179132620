import React, { useState } from 'react';
import styled from 'styled-components';




export const Intro = ({closePopUp, isDesktopBG}) => {
    const [showPopUp, setShowPopUp] = useState(true);

  return (
    <BGCover showPopUp={showPopUp}  onClick={()=>{
                setShowPopUp(false);
                closePopUp();
            }}>

     <CloseBtn src={'./icons/close.png'}/>          
    <Container>
            <ImgBG src={'./images/lightning.png'} isDesktopBG={isDesktopBG}/>

        <Wrapper>

            {/* <Heading>We know Web3 will become the future foundation to grow creativity and new exciting innovations. That’s why we’re constantly pushing the boundaries of what’s possible. Because when we all think differently, we can uncover better ways to get cool shit done.<br/><br/>At Gods & Titans, we’re fighting to shake things up, and together, we can rewrite history for good. A better community and ecosystem. Better incentives. Better opportunities for all. </Heading> */}
            <Heading>
            The Mint 2.0 FREE Mint mechanic uniquely combines primary (website) and secondary (OpenSea) sales during a mint and represents some of the best innovations from the Gods & Titans team. 
<br/>
<br/>
            Mint 2.0:
<br/>
<br/>

            -  Tier 1, GAT NFTs listed @ 0.049 ETH and below.
            <br/><div style={{marginLeft: '40px'}}>- Buy TWO GAT NFTs at this Tier from OpenSea to qualify for a FREE MINT. Immediately after buying TWO, go to godsandtitans.io and Mint ONE GAT NFT for FREE. </div>
<br/>

            - Tier 2, GAT NFTs listed @ 0.05 ETH and above.
                   <br/><div style={{marginLeft: '40px',}}>- Buy ONE GAT NFT at this Tier from OpenSea to qualify for a FREE MINT. Immediately after buying ONE, go to godsandtitans.io and Mint ONE GAT NFT for FREE. You can repeat the process until the supply is exhausted.</div>
<br/>

            - Alternatively, holders can choose to Mint up to 3 NFTs from godsandtitans.io for 0.05 ETH each. You may mint multiple times.
<br/>
<br/>

            - Non GAT holders (Public) can Mint up to 3 NFTs from godsandtitans.io for 0.075 ETH each. You may mint multiple times.


            </Heading>
        </Wrapper>
       
    </Container>
    </BGCover>
  )
}

const BGCover = styled.div`
    position: fixed;
    /* top: 50%;
    left: 50%; */
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.88);
    z-index: 2000000000;
    /* transform: translate(-50%,-50%); */
     user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    


    animation: grow 1s 1;

 ${({showPopUp})=> ((!showPopUp)? `
         animation: shrink 1s 1;
    ` : "")}
   
    @keyframes shrink {    
        0% { opacity: 1; clip-path: inset(0); transform: scale(1)  }
        100% {  opacity: 0; clip-path: inset(5%); transform: scale(0%) }
    }

    @keyframes grow {
         0% { opacity: 0; clip-path: inset(5%); transform: scale(111.11%) }
        100% { opacity: 1; clip-path: inset(0); transform: scale(1) }
    }

    /* @media screen and (min-width: ${props => props.theme.breakpoints.sm}){ */
    display: grid;
    place-items: center;
    /* } */


`

const Container = styled.div`
    /* background-color: red; */
    width: 100%;
    height: 90%;

    max-width: 1200px;
    max-height: 800px;
    min-height: 450px;
    position: absolute;
    display: grid;
    place-items: center;

    @media screen and (max-width: ${props => props.theme.breakpoints.sm}){
     width: 100%;
     min-height: 500px;

    }  
`

const ImgBG = styled.img`
    position: absolute;
    /* top: 50%;  */
    left: 50%;
    transform: translate( -50%,0);
    height: 100%;
    max-width: 1000px;

`


const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 70%;
    /* background-color: rgba(0,250,0,.7); */
    display: grid;
    place-items: center;
    padding: .2rem 1rem;
    max-width: 900px;
    overflow-y: hidden;

`



const Heading = styled.h1`
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1.2px;
    word-spacing: 1.2px;
    text-align: left;
    font-size: .9rem;
    color: white;


    @media screen and (max-height: 300px) and (max-width: 600px){
      font-size: .8rem;

    }  

     @media screen and (max-width: ${props => props.theme.breakpoints.sm}){
      font-size: .8rem;

    }  



`


const CloseBtn = styled.img`
    width: 50px;
	height: 50px;
    position: fixed; 
	top: 0px;
	right: 0px;
    margin: 1rem 1.5rem;
    z-index: 50;
    opacity: .75;
    transition: opacity 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &:hover{
    cursor: pointer;
  }


    @media screen and (max-width: ${props => props.theme.breakpoints.md}){
       margin: 24px;

    }  

  @media screen and (max-width:576px ) {
        width: 35px;
	    height: 35px;
    }

  
`