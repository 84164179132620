import React,{useEffect, useState, } from 'react'
import styled from 'styled-components'
import  MintBtn from './mintBtn';
import Web3 from "web3";
import { useMoralis} from "react-moralis";

import GATcontractAbi from '../GAT.json';

// import { Heading } from '../../global_styles';

const MINT_TIME = process.env.REACT_APP_MINT_DATE;


export const Landing = ({isDesktop, canMint, toggleMint,isLoading,wlMint,getMintPrice}) => {

    const [soldOut, setsoldOut] = useState(false);
    const { Moralis } = useMoralis();
    const [isTime, setIsTime] = useState(false);

    //////////////////     countdown logic      //////////////////////
 
    // presale date
    const target = new Date(MINT_TIME);

    // initial time difference setup
    const now = new Date();
    const difference = target.getTime() - now.getTime()

    // console.log(target)
    
    // calculates time
    const d = Math.floor(difference/ (1000 * 60 * 60 * 24)); 
    const h = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); 
    const m = Math.floor(
        (difference % (1000 * 60 * 60)) / (1000 * 60 )); 
    const s = Math.floor(
        (difference % (1000 * 60)) / (1000)); 


      const [days, setDays] = useState(d);
      const [hours, setHours] = useState(h);
      const [minutes, setMinutes] = useState(m);
      const [seconds, setSeconds] = useState(s);

      // time update func
      useEffect(() => {
          
          // update new time difference every 1000ms
          const interval = setInterval(()=>{

              // if (new Date(MINT_TIME) < (new Date())){
              if (new Date(MINT_TIME) < (new Date())){

                
                  setIsTime(true)
                  clearInterval(interval)
              }

              const now = new Date();
              const difference = target.getTime() - now.getTime()

              const d = Math.floor(difference/ (1000 * 60 * 60 * 24)); 
              setDays(d);

              const h = Math.floor(
                  (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); 
              setHours(h);

              const m = Math.floor(
                  (difference % (1000 * 60 * 60)) / (1000 * 60 )); 
              setMinutes(m);

              const s = Math.floor(
                  (difference % (1000 * 60)) / (1000)); 
              setSeconds(s);

             
          }, 1000)
          return () => clearInterval(interval)

      }, [])

      // `${days} days : ${hours} hours : ${minutes} minutes : ${seconds} seconds`

  return (
    <Container isDesktop={isDesktop}>
        <Wrapper isDesktop={isDesktop}>
  
          {/* <NavBtnLink to="">Connect Wallet</NavBtnLink> */}
           {/* <Heading>The World's first nft<br/>comicverse project</Heading> */}
           {/* {isDesktop ?  */}
            {isTime  ? '': <Subtitle isCountdown={true}>{`${days} day${days != 1 ?'s':''} : ${hours} hr : ${minutes} min : ${seconds} sec`}</Subtitle>}
            {/* {isTime  ? '': <Subtitle style={{zIndex:'10'}} isCountdown={true}>{`Mint temporarily paused`}</Subtitle>} */}

           <Subtitle >{!isTime ?'mint party: Nov. 10 @ 8pm est': <span style={{fontSize: '1.2rem'}}>MINTing open</span> }<br/>FREE MINT (restrictions apply)<br/>Public mint price: 0.075 eth{window.innerWidth< 430 ? <br/>:<> |</>} Holder mint price: 0.05 eth<br/>(Optional) Roblox Rewards Add-On : 0.05 ETH</Subtitle>
           {!isTime ? '':  
                <SubWrap isDesktop={isDesktop}>
                    <MintBtn 
                          getMintPrice={getMintPrice}
                          toggleMint={toggleMint} 
                          canMint={canMint}>Mint</MintBtn>
                </SubWrap>}
          {/* <MintBtn toggleMint={toggleMint} canMint={canMint} soldOut={soldOut}/> */}
           {/* :
              <></>} */}

               {/* <MintBtn toggleMint={toggleMint} canMint={canMint} soldOut={soldOut} wlMint={wlMint}></MintBtn> */}

              
        </Wrapper>
      </Container>  
  )
}



const Container = styled.div`
    position: absolute;
    top: ${({isDesktop}) => (isDesktop ? '0%' : '1.9%')};
    width: 100%;
    height: ${({isDesktop}) => (isDesktop ? '11%' : '15%')};
    /* background-color: rgba(250,50,50,.5); */
    /* min-width: ${props => props.theme.boxstyle.minWidth};*/
    /* max-width: ${props => props.theme.boxstyle.maxWidth};  */
    /* max-width: 800px; */
    margin: 0 auto;
    z-index: 8;
    
    /* background-color: red; */
`

const Wrapper = styled.div`
    position: absolute;
    right: 50%;
    width: ${({isDesktop}) => (isDesktop ? '100%' : '100%')};

    transform: translateX(50%);
    /* top: 58%; */
    top: ${({isDesktop}) => (isDesktop ? '56.5%' : '59.5%')};
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 650px;
    /* background-color: red; */
    /* background-color: rgba(255,255,255,.5); */
   
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 100%; */
    padding:.1rem .6rem;
/* >>>>>>> 81e373b79e488e35b8b74d03f8d41b4b7977cf2a */

     @media screen and (min-width: ${props => props.theme.breakpoints.lg}){
     /* width: 22%; */
    }


    /* @media screen and (max-width: ${props => props.theme.breakpoints.md}){
     top: 58%;
    }  */

`
const SubWrap = styled.div`
  /* position: absolute; 
  top: ${({isDesktop}) => (isDesktop ? '0%' : '500%')};
  background-color: red; */

`

const Heading = styled.h1`
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    /* font-weight: 800; */
    font-style: italic;
    letter-spacing: .075rem;
    text-align: center;
    font-size: 75%;
 
    @media screen and (max-width: ${props => props.theme.breakpoints.md}){
      /* font-size: .5rem; */

      font-size: 75%;

    }

     @media screen and (min-width: ${props => props.theme.breakpoints.lg}){
      /* font-size: .5rem; */
      font-size: 1.2rem;
    }


`

const Subtitle = styled.p`
/* z-index: 100000; */
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    /* font-weight: 800; */
    font-style: italic;
    /* letter-spacing: .075rem; */
    font-weight: 400;
    text-align: center;
    font-size: 85%;

    margin-bottom: ${({isCountdown}) => (isCountdown ? '1rem' : '.5rem')};
    margin-top: ${({isCountdown}) => (isCountdown ? '1rem' : '0')};

    /* width: 125%; */
      -webkit-box-shadow:0px 0px 30px 20px rgba(255,255,255,6);
    -moz-box-shadow: 0px 0px 30px 20px rgba(255,255,255,.6);
    box-shadow: 0px 0px 30px 20px rgba(255,255,255,.6);
    background-color: rgba(255,255,255,.525);
    border-radius: 100%;
    font-size: ${({isCountdown}) => (isCountdown ? '110%' : '90%')};

     @media screen and (max-width: ${props => props.theme.breakpoints.md}){
      /* font-size: .5rem; */
      font-size: ${({isCountdown}) => (isCountdown ? '100%' : '75%')};
    }


      @media screen and (min-width: ${props => props.theme.breakpoints.xl}){
      /* font-size: .5rem; */
        font-size: ${({isCountdown}) => (isCountdown ? '1.5rem' : '1rem')};
    }
`


function asyncCall(time) {
  return new Promise((resolve) => setTimeout(() => resolve(), time));//1500
}
