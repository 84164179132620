import React, { useEffect, useState } from 'react';
import { useMoralis} from "react-moralis"
import Web3 from "web3";
import styled from 'styled-components';
import contractAbi from '../GAT.json';

const CONTRACT_ADDRESS = process.env.REACT_APP_GAT_MINT_ADDRESS;
const COLLECTION_NAME =  process.env.REACT_APP_GAT_COLELCTION_NAME;
const COMPANION_COLLECTION_NAME =  process.env.REACT_APP_GAT_COMPANION_NAME;
const CONTRACT_OPENSEA_LINK = process.env.REACT_APP_CONTRACT_OPENSEA_LINK

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID


// console.log(firebase.apps.length )
const MintBtn = ({toggleMint, canMint, soldOut,wlMint,getMintPrice}) => {
    const { isAuthenticated, logout, user, Moralis } = useMoralis();
    const [ mintBtnText, setMintBtnText] = useState('Mint');
    const [showPopup, setShowpopup] = useState(false);
    const [status, setStatus] = useState(['']);
    const [mintAmt, updateAmt] = useState(1);
    const [rewardAmt, updaterewardAmt] = useState(1);

    let MINT_PRICE =  getMintPrice()[0];
    let MAX_AMT = getMintPrice()[1];
  
    const GAME_REWARD_PRICE =  process.env.REACT_APP_GAME_REWARD_PRICE

    // set mint btn back once wallet disconneccts
    useEffect(()=>{

        if(!isAuthenticated){
            setMintBtnText('Mint');
            toggleMint(false);
        }else{
            
            MINT_PRICE =  getMintPrice()[0]
            MAX_AMT = getMintPrice()[1];
            
            // console.log(MINT_PRICE, MAX_AMT)
        }

        if(soldOut){
            setMintBtnText('Minted Out');
        }



    },[isAuthenticated,soldOut])

    //////////// inc/dec logic ///////////////////
    const inc = () => {
        let newAmt = mintAmt + 1;

        if (newAmt > MAX_AMT) newAmt = MAX_AMT
        updateAmt(newAmt)
        updaterewardAmt(newAmt)
    }

    const dec = () => {
        let newAmt = mintAmt - 1;

        if (newAmt < 1) newAmt = 1
        updateAmt(newAmt)
        updaterewardAmt(newAmt)
    }

    const rewardInc = () => {
        let newAmt = rewardAmt + 1;

        if (newAmt > mintAmt) newAmt = mintAmt
        updaterewardAmt(newAmt)
    }

    const rewardDec = () => {
        let newAmt = rewardAmt - 1;

        if (rewardAmt < 1) newAmt = 0
        updaterewardAmt(newAmt)
    }


    //////////////////////////////////////////


    //////// close btn /////////////
    const closePopUp= ()=>{
      setTimeout(() => { setShowpopup(false) }, 900);
    }

    /////// minting functionality ////////
    const processMint = async ()=> {
         
        const web3Js = new Web3(Moralis.provider)

        let randomGeneratedHex = web3Js.utils.randomHex(32);

        setMintBtnText('Authenticating...');

        let authData = user.get('authData');   
        let objectID = user.id;   
        // console.log(objectID)        
        let authDataSiignature = authData.moralisEth.signature;

        if(user.get('ethAddress')){


            // pass objectID and use header x-auth
            // in firebase function query moralis db using wallet address
            // compare auth x from header to signature in authdata

             // total calculated eth value with mint and rewards
            //  var tokenPrice = wlMint ? 0 : MINT_PRICE;
             var tokenPrice = MINT_PRICE;
             var ethTotal = (tokenPrice * mintAmt) + (GAME_REWARD_PRICE * rewardAmt)
            // checks mint eligibility

            let controller = new AbortController();

            let timeoutId = setTimeout(() => controller.abort(), 30000);

            console.log('process mint')
            fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/processMint?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&price=${ethTotal}&each=${MINT_PRICE}`,
            {   
                signal: controller.signal,
                headers:{ 'x-authenticate': authDataSiignature}})
                .then(response =>response.json())
                .then(data => {  
                    clearTimeout(timeoutId);       
                    // console.log(data)

                    // retrieve salt and token from firebase function
                    const token = data.result.token;
                    const salt = data.result.salt;

                    // make contact with smart contract if salt & token found
                    if( salt && token){
                        setMintBtnText('Processing...');
                        
                        // CALL MINT METHOD
                        const contract = new web3Js.eth.Contract(contractAbi.abi, CONTRACT_ADDRESS);
                        contract.methods.mint(mintAmt, salt, token).send({from: user.get('ethAddress'), value: ethTotal}).then(async (e) => {
                            // mint successful
                            // console.log(e);

                            let mintedTokenData = [];
                            let gameRewardsRecieved = 0;

                            if (mintAmt > 1 ){
                                for(var i=0; i< e.events.Transfer.length; i++){
                                    let documentData = {
                                        contractAddress: e.events.Transfer[i].address,
                                        collectionName: CONTRACT_ADDRESS === e.events.Transfer[i].address ? COLLECTION_NAME : COMPANION_COLLECTION_NAME,
                                        walletAddress: user.get('ethAddress'),
                                        tokenId: e.events.Transfer[i].returnValues.tokenId,
                                        mintTime: Date.now(),
                                        transactionHash: e.transactionHash,
                                    }

                                    // game royalty addition
                                    // if(COMPANION_ADDRESS === e.events.Transfer[i].address){

                                        // game royalty field
                                        documentData = {
                                            ...documentData , 
                                            'gameRewards1': gameRewardsRecieved < rewardAmt ? true : false,
                                        }

                                        let tokenId = e.events.Transfer[i].returnValues.tokenId;

                                    

                                
                                        // setup royalty metadata
                                        // await fetch(`${ process.env.REACT_APP_FUNCTION_DOMAIN}/updateMetadata?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&tokenid=${tokenId}&hasgame=${gameRewardsRecieved < rewardAmt}`,
                                        // { 
                                        
                                        //     headers:{ 'x-authenticate': authDataSiignature}}
                                        // )
                                        // .then(response => response.json())
                                        // .then(data => {         
                                        //     console.log(data.result)
                                    
                                        // });

                                    
                                        // increment game royalty amount
                                        if (gameRewardsRecieved < rewardAmt){
                                            gameRewardsRecieved += 1;
                                        }
                                    
                                    // }

                                // writes to db with minted wallet info
                                    // addDoc(collection(db, "wallet_mint"), documentData);
                                    mintedTokenData.push(documentData)
                                }

                            }else{
                                // for(var i=0; i< e.events.Transfer.length; i++){
                                    let documentData = {
                                        contractAddress: e.events.Transfer.address,
                                        collectionName: CONTRACT_ADDRESS === e.events.Transfer.address ? COLLECTION_NAME : COMPANION_COLLECTION_NAME,
                                        walletAddress: user.get('ethAddress'),
                                        tokenId: e.events.Transfer.returnValues.tokenId,
                                        mintTime: Date.now(),
                                        transactionHash: e.transactionHash,
                                    }

                                    // game royalty addition
                                    // if(COMPANION_ADDRESS === e.events.Transfer[i].address){

                                        // game royalty field
                                        documentData = {
                                            ...documentData , 
                                            'gameRewards1': gameRewardsRecieved < rewardAmt ? true : false,
                                        }

                                        // let tokenId = e.events.Transfer[i].returnValues.tokenId;


                                        // setup royalty metadata
                                        // await fetch(`${ process.env.REACT_APP_FUNCTION_DOMAIN}/updateMetadata?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&tokenid=${tokenId}&hasgame=${gameRewardsRecieved < rewardAmt}`,
                                        // { 
                                        
                                        //     headers:{ 'x-authenticate': authDataSiignature}}
                                         // )
                                        // .then(response => response.json())
                                        // .then(data => {         
                                        //     console.log(data.result)
                                    
                                        // });

                                    
                                        // increment game royalty amount
                                        if (gameRewardsRecieved < rewardAmt){
                                            gameRewardsRecieved += 1;
                                        }
                                    
                                    // }

                                // writes to db with minted wallet info
                                    // addDoc(collection(db, "wallet_mint"), documentData);
                                    mintedTokenData.push(documentData)
                                // }

                            }

                            // console.log(mintedTokenData)
                            // console.log(mintedTokenData.length)
                            
                            if(mintedTokenData.length){
                                let stringData = encodeURIComponent(JSON.stringify(mintedTokenData));
                             
                                // console.log(stringData);

                                // update mint DB
                                fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/updateMintDB?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&data=${stringData}`,
                                {headers:{ 'x-authenticate': authDataSiignature}})
                                    .then(response => response.json())
                                    .then(data => {
                                         setMintBtnText('Mint Complete');
                                         updateAmt(0);
                                       
                                     });

                                // update analytics log
                                let resultInfo = {collection_name : 'click_mint', status: 'pass', condition: 'mint complete'}
                                let stringResult = encodeURIComponent(JSON.stringify(resultInfo));
                                fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/analyticsUpdate?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&resultinfo=${stringResult}`,
                                 {headers:{ 'x-authenticate': authDataSiignature}}).catch(e=>{
                                    console.log(e)
                                    setMintBtnText('Mint');
                                    toggleMint(false);
                                    logout(); 
                                })

                            }else{
                                setMintBtnText('Mint');
                                toggleMint(false);
                                logout();  
                            }

                        }).catch(err=>{
                         
                            setMintBtnText('Mint');
                            if (err.code != 4001 ){
                                toggleMint(false);
                                logout();
                            }
                            
                            // mint did not go through
                            console.log(err);

                            // update analytics log
                            let resultInfo = {collection_name : 'click_mint', status: 'failed', condition: err.message}
                            resultInfo = encodeURIComponent(JSON.stringify(resultInfo));
                            console.log(resultInfo)
                            fetch(`${process.env.REACT_APP_FUNCTION_DOMAIN}/analyticsUpdate?address=${user.get('ethAddress')}&nonce=${randomGeneratedHex}&objectid=${objectID}&resultinfo=${resultInfo}`,
                                {headers:{ 'x-authenticate': authDataSiignature}}).catch(e=>{
                                    console.log(e)
                                    setMintBtnText('Mint');
                                    toggleMint(false);
                                    logout(); 
                                })
                        })    
                    }else{
                        console.log('no salt or token')
                        setMintBtnText('Mint');
                        toggleMint(false);
                        logout(); 
                    }
                });            
        }else{
            console.log(' no wallet addresss')
        }      
    }

  return (
  <MintContainer isComplete={mintBtnText==='Mint Complete' || mintBtnText ==='Minted Out'} canMint={canMint}>
    {(!canMint && mintBtnText !== 'Minted Out') && <MintDisabled/>}
    
    {/* mint amount */}
   <MintLabelGroup  isComplete={mintBtnText==='Mint Complete' || mintBtnText ==='Minted Out'}>
        <MintLabel>
            <MintLabelText>Mint Amount: </MintLabelText>
        </MintLabel>
    <BtnGroup>
        <BtnBox onClick={(e)=>{
            e.preventDefault()
            if(isAuthenticated && canMint && mintBtnText==='Mint')  { dec() };
        }}>
            <BtnText>-</BtnText>
        </BtnBox>

        <TextBox>
            <NumText>{mintAmt}</NumText>
        </TextBox>

        <BtnBox onClick={(e)=>{
            e.preventDefault()
            if(isAuthenticated && canMint && mintBtnText==='Mint')  { inc() };
        }}>
            <BtnText>+</BtnText>
        </BtnBox>

    </BtnGroup>
    </MintLabelGroup>

    <Divider isComplete={mintBtnText==='Mint Complete' || mintBtnText ==='Minted Out'}/>

    {/* royalty amount */}
    <MintLabelGroup isComplete={mintBtnText==='Mint Complete' || mintBtnText ==='Minted Out'}>
        <MintLabel>
            <MintLabelText>Roblox Rewards: </MintLabelText>
        </MintLabel>
    <BtnGroup >
        <BtnBox onClick={(e)=>{
            e.preventDefault()
            if(isAuthenticated && canMint && mintBtnText==='Mint')  { rewardDec() };
        }}>
            <BtnText>-</BtnText>
        </BtnBox>

        <TextBox>
            <NumText>{rewardAmt}</NumText>
        </TextBox>

        <BtnBox onClick={(e)=>{
            e.preventDefault()
            if(isAuthenticated && canMint && mintBtnText==='Mint')  { rewardInc() };
        }}>
            <BtnText>+</BtnText>
        </BtnBox>

    </BtnGroup>
    </MintLabelGroup>

     <MintBtnContainer 
            isComplete={mintBtnText==='Mint Complete'|| mintBtnText ==='Minted Out'}
        onClick={(e)=>{
            e.preventDefault()
            if(isAuthenticated && mintAmt > 0 && mintBtnText==='Mint') { 
                processMint() 
            }else if(mintBtnText==='Mint Complete'){
                // setTimeout(() => {
                    openInNewTab(`${CONTRACT_OPENSEA_LINK}/${user.get('ethAddress')}`)
                    setMintBtnText('Mint');
                    toggleMint(false);
                    updateAmt(1);
                    updaterewardAmt(1);
                    logout();  
                // }, 2000);
             }else if(mintBtnText==='Minted Out'){
                    openInNewTab('https://opensea.io/collection/gods-and-titans-titanomachy-war')
             };
        }}>
            <Text mintBtnText={mintBtnText}>{mintBtnText}</Text>
    </MintBtnContainer>
    {/* <!-- Gods & Titans Test on Ethereum Rinkeby -->  */}

        {/* {showPopup && <ModalWrapper><Modal closePopUp={closePopUp} status={status}/></ModalWrapper>} */}
    </MintContainer>

  )
}

export default MintBtn

const MintContainer = styled.div`
    position: relative  ;
    /* margin-top: .5rem; */
    display: grid;
    place-items:center;
    opacity:${({canMint})=>(canMint ? '':' .75')};
    transition: 1 ease-in all;
    background-color: rgba(0,0,0,.45);
    padding: ${({isComplete})=>(isComplete ? '0rem':'.75rem 1rem')};
    /* width: 75%; */

     -webkit-box-shadow:0px 0px 15px 10px rgba(255,255,255,6);
    -moz-box-shadow: 0px 0px 15px 10px rgba(255,255,255,.6);
    box-shadow: 0px 0px 15px 10px rgba(255,255,255,.6);

    @media screen and (max-width: 1250px) {
        padding: ${({isComplete})=>(isComplete ? '0rem':'.75rem ')};
    }


`

const MintDisabled = styled.div`
    position: absolute;
    bottom:0;
    left: 0;
    height: 11.4rem;
    width: 100%;
    background-color: rgba(50,50,50,.55);
    /* outline: 2px solid white; */
    z-index: 50;

     @media screen and (max-width: 1250px) {
        height: 8.65rem;

        
    }

`

const MintLabelGroup = styled.div`
    display: ${({isComplete})=>(isComplete ? 'none': 'flex')};
    /* margin: .2rem auto; */
`

const MintLabel = styled.div`
    width: 50%;
    max-width: 100px;
    margin: 0 .2rem;
    /* background-color: yellow; */
    display: grid;
    place-items: center;
`


const MintLabelText = styled.h2`
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    text-align: left;
    text-transform: uppercase;
    vertical-align: center;

     @media screen and (max-width: 1250px) {
         font-size: .8rem;
    }

`

const Divider = styled.span`
    width: 100%;
    border-top: 1px white solid;
    margin: .3rem auto;
     display: ${({isComplete})=>(isComplete ? 'none': 'block')};

`

const BtnGroup = styled.div`
    /* margin-top: .5rem; */
    display: ${({isComplete})=>(isComplete ? 'none': 'flex')};
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    /* background-color: teal; */
    width: 9.5rem;
    -webkit-filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));

    @media screen and (max-width: 1250px) {
        height: 2rem;
        width: 7.5rem;
        
    }

`

const BtnBox = styled.div`
    height: 2.5rem;
    width: 2.5rem;
    border: 2px solid white;
    background-color: rgba(0,0,0,.55);
    display: grid;
    place-items: center;
    /* background-color: transparent; */

        &:hover {
        cursor: pointer;
        border: 2px solid white;
        background-color: rgba(0,0,0,.85);
         opacity: 1;
    }


     @media screen and (max-width: 1250px) {
          height:2rem;
          width: 2rem;
    }

`

const TextBox = styled.div`
    height: 100%;
    display: grid;
    place-items: center;
    /* background-color: blue; */
  
`


const NumText = styled.h1`
    height: 100%;

    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    color: #fff;
    text-decoration: none;
    font-size: 2.5rem;
    text-align: center;
    text-transform: uppercase;
    /* background-color: red; */
     /* -webkit-text-stroke: 2px black; */
  
    @media screen and (max-width: 1250px) {
        font-size: 1.75rem;
        
    }


`



const BtnText = styled.h2`
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;

     @media screen and (max-width: 1250px) {
         font-size: 1rem;
    }

`

const MintBtnContainer = styled.div`
    margin-top: ${({isComplete})=>(isComplete ? '0rem':'.5rem')};;;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
   
    /* height: 100%; */
    /* border: 2px solid transparent; */
    border: 2px solid white;
    background-color: rgba(0,0,0,.55);
    /* background-color: transparent; */

        &:hover {
        cursor: pointer;
        border: 2px solid white;
        background-color: rgba(0,0,0,.85);
         opacity: 1;
    } 

    @media screen and (max-width: 1250px) {
          height: ${({isComplete})=>(isComplete ? '':'2rem')};;
    }

    padding: ${({isComplete})=>(isComplete ? '.75rem .5rem':'0')};;
   
`

const Text = styled.h2`
    margin: .5rem 1.5rem;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: italic;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;

      @media screen and (max-width: 1250px) {
         font-size: 1rem;
    }

   /* ${({mintBtnText})=>(mintBtnText==='Processing...'? `
        display:inline-block;
        clip-path: inset(0  .85rem 0 0);
        animation: l 1s steps(4) infinite;

        @keyframes l {
            to {
                clip-path: inset(0 -.25rem 0 0)
            }
        }
   /* `:'')}  */


`



const ModalWrapper = styled.div `
    position: absolute;
    top: -100%;
    left: -50%;
    height: 100%;
    width: 200%;
    transform: translate(0, 50%);
    /* background-color: red; */

`

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}