import React, { useState } from 'react';
import styled from 'styled-components';


let preview = [
    {
        title: 'the comic studio',
        path: './images/studioPreview.png'
    },
     {
        title: 'minting 2.0 vault',
        path: './images/vaultPreview.png'
    },
]

export const Popup = ({closePopUp}) => {
    const [showPopUp, setShowPopUp] = useState(true);

  return (
    <BG>
        <Container showPopUp={showPopUp}  onClick={()=>{
                    setShowPopUp(false);
                    closePopUp();
                }}>
            
            <Wrapper>
                <CloseBtn src={'./icons/close.png'}/>
                <Heading>View on desktop to<br/>experience:</Heading>
                <ImgColumn>
                
                {preview.map((img, index)=>{
                    return <ImgBox key={index}>
                        <Sub>{img.title}</Sub>
                        <ImgPreview src={img.path}/>
                    </ImgBox>
                })}
                    

                </ImgColumn>

            </Wrapper>
        </Container>
    </BG>
  )
}

const BG = styled.div`
    position: fixed;
    background-color: transparent;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: 100;
    display: grid;
    place-items: center;

`

const Container = styled.div`
    position: relative;
    /* margin: au auto; */
    background-color: rgba(0,0,0,.9);
    width: 85%;
    /* height: 90%; */
    max-width: 700px;
    max-height: 700px;
    animation: grow 1.6s 1;

    padding: 1rem; 
    padding-top: 55px;
    @media screen and (max-width:576px ) {
        padding-top: 50px;
    }

    ${({showPopUp})=> ((!showPopUp)? `
         animation: shrink 1s 1;
    ` : "")}
   
     @keyframes shrink {    
        0% { opacity: 1; clip-path: inset(0); transform: scale(1)  }
        100% {  opacity: 0; clip-path: inset(5%); transform: scale(0%) }
    }

    @keyframes grow {
         0% { opacity: 0; clip-path: inset(5%); transform: scale(111.11%) }
        100% { opacity: 1; clip-path: inset(0); transform: scale(1) }
    }

`

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
`

const CloseBtn = styled.img`
    width: 30px;
	height: 30px;
    position: absolute; 
	top: 0px;
	right: 0px;
    margin: 1rem;
    z-index: 50;
    opacity: .75;
    transition: opacity 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &:hover{
    cursor: pointer;
  }

  @media screen and (max-width:576px ) {
        width: 25px;
	    height: 25px;
    }
  
`


const ImgColumn = styled.div`
    margin-top: 1rem;
    width: 80%;
    height: 100%;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media screen and (orientation: landscape){
         flex-direction: row;
    }
`


const ImgBox = styled.div`
    width: 100%;
    max-width: 250px;
    margin: 1rem;
   
    
`

const ImgPreview = styled.img`
    width: 100%;
    border: 2px white solid;
    margin-top: .5rem;
    
`


const Heading = styled.h1`
    text-transform: uppercase;
    /* font-weight: 800; */
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.4rem;
    color: white;


    @media screen and (max-width: ${props => props.theme.breakpoints.sm}){
      font-size: 1rem;
    }
    
`

const Sub = styled.h2`
    text-transform: uppercase;
    /* font-weight: 800; */
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.1rem;
    color: white;



    @media screen and (max-width: ${props => props.theme.breakpoints.sm}){
      font-size: .8rem;
    }
    
`